import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import LoanStructureForm from './LoanStructureForm';
import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';

const FormEditor = ({ formData, handleChange, handleSave, handleCancel, handleCapTableChange, handleAddCapTableRow, handleDeleteCapTableRow }) => {
  const formatCurrency = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? '' : number.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
  };

  const handleCurrencyChange = (event) => {
    const { name, value } = event.target;
    const formattedValue = value.replace(/[^0-9]/g, '');
    handleChange({ target: { name, value: formattedValue } });
  };

  const handleLoanPurposeChange = (event) => {
    const { name, value } = event.target;
    let updatedLoanStructure = { ...formData.loanStructure };

    if (value === 'workingCapital') {
      updatedLoanStructure = {
        equityInjection: { personal: '0', fullStandby: '0' },
        conventionalLoan: '0',
        sba7aLoan: '100',
        sellersNote: '0'
      };
    }

    handleChange({
      target: {
        name: name,
        value: value
      }
    });

    handleChange({
      target: {
        name: 'loanStructure',
        value: updatedLoanStructure
      }
    });
  };

  if (!formData || !formData.capTable) {
    return (
      <div className="mt-6 bg-gray-50 p-6 rounded-lg shadow">
        <h1 className="text-2xl font-bold mb-6 text-gray-900">Error Loading Form</h1>
        <p className="text-red-600">
          Unable to load form data. Please ensure you are logged in and try refreshing the page.
        </p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Refresh Page
        </button>
      </div>
    );
  }

  return (
    <div className="mt-6 bg-gray-50 p-6 rounded-lg shadow">
      <h1 className="text-2xl font-bold mb-6 text-gray-900">Business Loan Application</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        
        {/* Common Questions Section */}
        <div className="bg-white p-4 rounded-md shadow col-span-2">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Common Questions</h3>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label htmlFor="businessSummary" className="block text-sm font-medium text-gray-700 mb-1">
                Business Summary
              </label>
              <textarea
                id="businessSummary"
                name="businessSummary"
                value={formData.businessSummary || ''}
                onChange={handleChange}
                className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                rows="4"
              />
            </div>
            <div>
              <label htmlFor="businessState" className="block text-sm font-medium text-gray-700 mb-1">
                Business State
              </label>
              <input
                type="text"
                id="businessState"
                name="businessState"
                value={formData.businessState || ''}
                onChange={handleChange}
                className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>

        {/* Loan Details Section */}
        <div className="bg-white p-4 rounded-md shadow col-span-2">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Loan Details</h3>
          <div className="grid grid-cols-1 gap-4">
            {/* Add Loan Purpose dropdown */}
            <div>
              <label htmlFor="loanPurpose" className="block text-sm font-medium text-gray-700 mb-1">
                Loan Purpose
              </label>
              <select
                id="loanPurpose"
                name="loanPurpose"
                value={formData.loanPurpose || ''}
                onChange={handleLoanPurposeChange}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="">Select a purpose</option>
                <option value="Acquisition">Acquisition</option>
                <option value="workingCapital">Working Capital</option>
                <option value="workingCapital-startup">Working Capital (Startup)</option>
              </select>
            </div>

            {/* Loan Size for all loan types */}
            <div>
              <label htmlFor="loanSize" className="block text-sm font-medium text-gray-700 mb-1">
                Loan Size
              </label>
              <NumericFormat
                id="loanSize"
                name="loanSize"
                value={formData.loanSize}
                onValueChange={(values) => handleChange({ target: { name: 'loanSize', value: values.value } })}
                thousandSeparator={true}
                prefix={'$'}
                className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="$0"
              />
            </div>

            {/* Conditional rendering based on loanPurpose */}
            {(formData.loanPurpose === 'Acquisition' || formData.loanPurpose === 'workingCapital') && (
              <div>
                <label htmlFor="businessAge" className="block text-sm font-medium text-gray-700 mb-1">
                  Business Age (years)
                </label>
                <input
                  type="number"
                  id="businessAge"
                  name="businessAge"
                  value={formData.businessAge || ''}
                  onChange={handleChange}
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            )}

            {formData.loanPurpose === 'Acquisition' && (
              <div>
                <label htmlFor="realEstateAppraisal" className="block text-sm font-medium text-gray-700 mb-1">
                  Real Estate Appraisal
                </label>
                <NumericFormat
                  id="realEstateAppraisal"
                  name="realEstateAppraisal"
                  value={formData.realEstateAppraisal}
                  onValueChange={(values) => handleChange({ target: { name: 'realEstateAppraisal', value: values.value } })}
                  thousandSeparator={true}
                  prefix={'$'}
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  placeholder="$0"
                />
              </div>
            )}

            {formData.loanPurpose === 'workingCapital' && (
              <div>
                <label htmlFor="workingCapitalUse" className="block text-sm font-medium text-gray-700 mb-1">
                  Use of Working Capital
                </label>
                <textarea
                  id="workingCapitalUse"
                  name="workingCapitalUse"
                  value={formData.workingCapitalUse || ''}
                  onChange={handleChange}
                  rows="4"
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            )}

            {/* No additional fields for workingCapital-startup */}
          </div>
        </div>

        {/* Cap Table Section */}
        <div className="bg-white p-4 rounded-md shadow col-span-2">
          <h3 className="text-lg font-medium text-gray-900 mb-2 flex items-center">
            Cap Table
            <span className="relative group">
              <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max p-2 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                Ownership structure of your business
              </span>
            </span>
          </h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Percentage</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {formData.capTable.map((row, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="text"
                        value={row.name}
                        onChange={(event) => handleCapTableChange(index, 'name', event.target.value)}
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          value={row.percentage}
                          onChange={(event) => handleCapTableChange(index, 'percentage', event.target.value)}
                          className="focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                          placeholder="0.00"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">%</span>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleDeleteCapTableRow(index)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            onClick={handleAddCapTableRow}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Cap Table Row
          </button>
        </div>
      </div>

      {formData.loanPurpose !== 'workingCapital' && (
        <LoanStructureForm formData={formData} handleChange={handleChange} />
      )}

      <div className="mt-6 flex justify-end space-x-3">
        <button
          onClick={handleCancel}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default FormEditor;
