import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import lenderData from './loanbrokerdata.json';
import { generateSummaryPDF } from './PdfGenerator';
import { pdf, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Image } from '@react-pdf/renderer';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase'; // Ensure 'storage' is properly initialized in your Firebase setup
import LoanPaymentCalculator from './LoanPaymentCalculator'; // Import the LoanPaymentCalculator component
import ImportantFinancials from './ImportantFinancials'; // Import the ImportantFinancials component
import { CheckIcon, CheckCircleIcon, MinusCircleIcon, ChevronLeftIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import LoanFiles from './loanfiles';
import { PaperClipIcon, ArrowLeftIcon ,DocumentTextIcon, ArrowDownTrayIcon, ArrowUpIcon, ArrowDownIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import LoanOfficerAssignment from './LoanOfficerAssignment';
import OcrReview from './OcrReview';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { Fragment } from 'react';
import DebtServiceCoverageCalculator from './DebtServiceCoverageCalc';
import { documentRequirements } from '../customerPortal/documentTracker';
import FinancialMetricsDisplayAdmin from './FinancialMetricsDisplayAdmin';

const replaceNullsWithEmptyStrings = (obj) => {
  if (typeof obj !== 'object' || obj === null || obj === undefined) {
    return obj === null || obj === undefined ? '' : obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(replaceNullsWithEmptyStrings);
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = replaceNullsWithEmptyStrings(value);
    return acc;
  }, {});
};

const fetchDocuments = async (loanId) => {
  try {
    const documentsRef = collection(db, 'documentMetadata');
    const q = query(documentsRef, where('loanApplicationId', '==', loanId));
    const querySnapshot = await getDocs(q);
    const documentsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Group documents by label
    const groupedDocuments = {};
    documentsData.forEach((document) => {
      if (!groupedDocuments[document.label]) {
        groupedDocuments[document.label] = [];
      }
      groupedDocuments[document.label].push(document);
    });

    return groupedDocuments;
  } catch (error) {
    console.error('Error fetching documents:', error);
    return {};
  }
};

const fetchAndMatchLenders = async (loan) => {
  try {
    const lendersRef = collection(db, 'banks');
    const lendersSnapshot = await getDocs(lendersRef);
    const lenders = lendersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    console.log('All lenders:', lenders);
    console.log('Loan details:', loan);

    const matchedLenders = lenders.filter(lender => {
      console.log(`\nChecking lender: ${lender.bankName}`);

      // Check if the lender serves the business state
      const lenderStates = Array.isArray(lender.state) ? lender.state : [lender.state];
      const stateMatch = lenderStates.includes(loan.businessState) || lenderStates.length === 50;
      
      if (!stateMatch) {
        console.log(`State mismatch: Lender states - ${lenderStates.join(', ')}, Business state - ${loan.businessState}`);
        return false;
      }

      // Check if the loan amount is within the lender's range
      const loanAmount = parseFloat(loan.loanSize);
      if (lender.optimalLoanAmount && lender.optimalLoanAmount.min && lender.optimalLoanAmount.max) {
        if (loanAmount < lender.optimalLoanAmount.min || loanAmount > lender.optimalLoanAmount.max) {
          console.log(`Loan amount mismatch: Lender range - ${lender.optimalLoanAmount.min} to ${lender.optimalLoanAmount.max}, Loan amount - ${loanAmount}`);
          return false;
        }
      } else {
        console.log(`Lender ${lender.bankName} is missing optimalLoanAmount data`);
      }

      // Check credit score requirement (if available)
      const creditScore = Math.max(...Object.values(loan.creditScores).map(score => parseInt(score)));
      if (lender.creditScore && creditScore < lender.creditScore) {
        console.log(`Credit score mismatch: Required - ${lender.creditScore}, Actual - ${creditScore}`);
        return false;
      }

      // Check DSCR requirement (if available)
      const currentYearDSCR = loan?.financialAnalysis?.['Business Tax Return - Current Year']?.dscr;
      if (currentYearDSCR && lender.debtServiceRequirement && currentYearDSCR < lender.debtServiceRequirement) {
        console.log(`DSCR mismatch: Required - ${lender.debtServiceRequirement}, Actual - ${currentYearDSCR}`);
        return false;
      }

      console.log(`Lender ${lender.bankName} matched`);
      return true;
    });

    console.log('Matched lenders:', matchedLenders);

    // Sort lenders based on a composite score (lower is better)
    const sortedLenders = matchedLenders.sort((a, b) => {
      const scoreA = ((a.usualLoanRateMin + a.usualLoanRateMax) / 2 || 0) + (a.processTime || 0) / 30 + (a.termSheetTime || 0) / 7 - (a.approvalPercentage || 0) / 100;
      const scoreB = ((b.usualLoanRateMin + b.usualLoanRateMax) / 2 || 0) + (b.processTime || 0) / 30 + (b.termSheetTime || 0) / 7 - (b.approvalPercentage || 0) / 100;
      return scoreA - scoreB;
    });

    return sortedLenders; // Return all matched lenders
  } catch (error) {
    console.error('Error fetching and matching lenders:', error);
    return [];
  }
};

const LoanDetails = () => {
  const { loanId } = useParams();
  const navigate = useNavigate();
  const [loan, setLoan] = useState(null);
  const [documentsByLabel, setDocumentsByLabel] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [topLenders, setTopLenders] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [deductions, setDeductions] = useState([]);
  const [personalGuarantorResumes, setPersonalGuarantorResumes] = useState([]);
  const [resumeScore, setResumeScore] = useState(0);
  const [isApplicantInfoExpanded, setIsApplicantInfoExpanded] = useState(true);
  const [expandedSections, setExpandedSections] = useState({});
  const [selectedDeductions, setSelectedDeductions] = useState({
    'Business Tax Return - Current Year': {},
    'Business Tax Return - Last Year': {},
    'Business Tax Return - 2 Years Ago': {}
  });
  const [isLoanFilesExpanded, setIsLoanFilesExpanded] = useState(true);
  const [isLoanCalculatorExpanded, setIsLoanCalculatorExpanded] = useState(true);
  const [isDebtServiceCalcExpanded, setIsDebtServiceCalcExpanded] = useState(true);
  const [personalFinancialData, setPersonalFinancialData] = useState([]);
  const [activeGuarantorIndex, setActiveGuarantorIndex] = useState(0);

  const [steps, setSteps] = useState([
    { 
      id: '01', 
      name: 'Pre-Check', 
      description: 'Initial document review',
      href: '#', 
      status: 'upcoming',
      items: [
        { name: 'Personal Financial Statement', statusKey: 'isPersonalFinancialStatementComplete' },
        { name: 'Credit Bureau Report', statusKey: 'isCreditBureauReportComplete' },
        { name: 'Resumes for Key Team Members', statusKey: 'isResumesComplete' },
        { name: 'Personal Tax Returns', statusKey: 'isPersonalTaxReturnsComplete' },
      ]
    },
    { 
      id: '02', 
      name: 'Loan Application', 
      description: 'Application form completion',
      href: '#', 
      status: 'upcoming',
      items: [
        { name: 'Complete Application Form', statusKey: 'isApplicationComplete' },
      ]
    },
    { 
      id: '03', 
      name: 'Term Sheet', 
      description: 'Final review and approval',
      href: '#', 
      status: 'upcoming',
      items: [
        { name: 'Business Tax Returns', statusKey: 'isTermSheetComplete' },
      ]
    },
  ]);
  const [personalGuarantors, setPersonalGuarantors] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [matchedLenders, setMatchedLenders] = useState([]);
  const [assigningBank, setAssigningBank] = useState(null);
  const [isDropboxModalOpen, setIsDropboxModalOpen] = useState(false);
  const [dropboxLink, setDropboxLink] = useState('');
  const [assigningBankId, setAssigningBankId] = useState(null);
  const [assignedBanks, setAssignedBanks] = useState({});
  const [ocrData, setOcrData] = useState({});

  const toggleApplicantInfo = () => {
    setIsApplicantInfoExpanded(!isApplicantInfoExpanded);
  };

  const toggleLoanFiles = () => {
    setIsLoanFilesExpanded(!isLoanFilesExpanded);
  };

  const toggleLoanCalculator = () => {
    setIsLoanCalculatorExpanded(!isLoanCalculatorExpanded);
  };
  const calculateNetWorth = (data) => {
    if (!data) return 0;
    return data.Assets.Total - data.Liabilities.TotalLiabilities;
  };
  const checkDocumentProgress = () => {
    const documentStatus = documentRequirements.reduce((acc, doc) => {
      if (doc.Individual) {
        acc[doc.Name] = personalGuarantors.every((guarantor) =>
          doc.Labels.every((label) =>
            uploadedDocuments.some(
              (uploadedDoc) =>
                uploadedDoc.documentName === doc.Name &&
                uploadedDoc.ownerId === `${guarantor.firstName} ${guarantor.lastName}` &&
                uploadedDoc.label === label
            )
          )
        );
      } else {
        acc[doc.Name] = doc.Labels.every((label) =>
          doc.Optional
            ? true
            : uploadedDocuments.some(
                (uploadedDoc) =>
                  uploadedDoc.documentName === doc.Name &&
                  uploadedDoc.ownerId === null &&
                  uploadedDoc.label === label
              )
        );
      }
      return acc;
    }, {});

    const preCheckComplete = documentRequirements
      .filter((doc) => doc.Individual)
      .every((doc) => documentStatus[doc.Name]);

    const businessDocumentsComplete = documentRequirements
      .filter((doc) => !doc.Individual)
      .every((doc) => documentStatus[doc.Name]);

    const requiredFields = [
      'businessAge',
      'businessState',
      'businessSummary',
      'ebitda',
      'loanSize',
      'realEstateAppraisal',
      'revenue',
    ];
    const formComplete = requiredFields.every((field) => !!loan[field]);

    const creditScoreComplete =
      loan &&
      loan.creditScores &&
      Object.values(loan.creditScores).some((score) => score > 0);

    return {
      documentStatus,
      preCheckComplete,
      businessDocumentsComplete,
      formComplete,
      creditScoreComplete,
    };
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(() => {
    if (loan && loan.resumeScore !== undefined) {
      setResumeScore(loan.resumeScore);
    }
  }, [loan]);

  useEffect(() => {
    const fetchLoanDetails = async () => {
      if (!loanId) {
        console.error('Loan ID is missing');
        navigate('/admin-portal');
        return;
      }

      try {
        const loanDoc = await getDoc(doc(db, 'loanApplications', loanId));
        if (loanDoc.exists()) {
          const loanData = loanDoc.data();
          setLoan({ id: loanDoc.id, ...loanData });
          setPersonalGuarantors(loanData.personalGuarantors || []);
        } else {
          console.error('Loan not found');
          navigate('/admin-portal');
        }
      } catch (error) {
        console.error('Error fetching loan details:', error);
      }
    };

    const fetchOCRData = async () => {
      if (!loanId) return;

      try {
        const ocrMetadataRef = collection(db, 'OCRMetadata');
        const q = query(ocrMetadataRef, where('loanApplicationId', '==', loanId));
        const querySnapshot = await getDocs(q);
        
        const processedData = {};
        const personalFinancialOCRs = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.documentName === "Business Tax Returns") {
            const year = getYearFromLabel(data.label);
            processedData[year] = {
              ordinaryBusinessIncome: parseFloat(data.ocrResult?.ordinary_business_income) || 0,
              grossRevenue: parseFloat(data.ocrResult?.gross_receipts_or_sales) || 0,
              officerCompensation: parseFloat(data.ocrResult?.compensation_of_officers) || 0,
              interest: parseFloat(data.ocrResult?.interest) || 0,
              depreciation: parseFloat(data.ocrResult?.depreciation) || 0,
              otherDeductions: data.ocrResult?.other_deductions || {},
              year: year,
            };
          } else if (data.documentName === "Personal Financial Statement") {
            personalFinancialOCRs.push(data.ocrResult);
          }
        });

        setOcrData(processedData);
        setPersonalFinancialData(personalFinancialOCRs);
      } catch (error) {
        console.error('Error fetching OCR data:', error);
      }
    };

    fetchLoanDetails();
    fetchOCRData();
  }, [loanId, navigate]);

  const getYearFromLabel = (label) => {
    const yearLabels = {
      'Business Tax Return - Current Year': '2023',
      'Business Tax Return - Last Year': '2022',
      'Business Tax Return - 2 Years Ago': '2021'
    };
    return yearLabels[label] || label;
  };

  useEffect(() => {
    const fetchLoanDocuments = async () => {
      const groupedDocuments = await fetchDocuments(loanId);
      setDocumentsByLabel(groupedDocuments);
    };

    fetchLoanDocuments();
  }, [loanId]);

  useEffect(() => {
    if (loan) {
      const {
        documentStatus,
        preCheckComplete,
        businessDocumentsComplete,
        formComplete,
        creditScoreComplete,
      } = checkDocumentProgress();

      const updatedSteps = [
        {
          id: '01',
          name: 'Pre-Check',
          description: 'Initial document review',
          status: preCheckComplete && creditScoreComplete ? 'complete' : 'upcoming',
          items: documentRequirements
            .filter((doc) => doc.Individual)
            .map((doc) => ({
              name: doc.Name,
              status: documentStatus[doc.Name] ? 'complete' : 'upcoming',
            })),
        },
        {
          id: '02',
          name: 'Loan Application',
          description: 'Application form completion',
          status: formComplete
            ? 'complete'
            : preCheckComplete && creditScoreComplete
            ? 'current'
            : 'upcoming',
          items: [
            { name: 'Complete Application Form', status: formComplete ? 'complete' : 'upcoming' },
          ],
        },
        {
          id: '03',
          name: 'Term Sheet',
          description: 'Final review and approval',
          status: businessDocumentsComplete ? 'complete' : formComplete ? 'current' : 'upcoming',
          items: documentRequirements
            .filter((doc) => !doc.Individual)
            .map((doc) => ({
              name: doc.Name,
              status: documentStatus[doc.Name] ? 'complete' : 'upcoming',
            })),
        },
      ];

      setSteps(updatedSteps);
    }
  }, [loan, uploadedDocuments, personalGuarantors]);

  useEffect(() => {
    const getLenders = async () => {
      if (loan) {
        const lenders = await fetchAndMatchLenders(loan);
        setMatchedLenders(lenders);
      }
    };
    getLenders();
  }, [loan]);

  const fetchBankAssignments = async () => {
    try {
      const assignmentsRef = collection(db, 'bankAssignments');
      const q = query(assignmentsRef, where('applicationId', '==', loanId));
      const querySnapshot = await getDocs(q);
      const assignments = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        assignments[data.bankId] = data;
      });
      setAssignedBanks(assignments);
    } catch (error) {
      console.error('Error fetching bank assignments:', error);
    }
  };

  useEffect(() => {
    fetchBankAssignments();
  }, [loanId]);

  const handleGenerateSummaryPDF = async () => {
    try {
      const pdfBlob = await generateSummaryPDF(loan);
      if (pdfBlob instanceof Blob) {
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, '_blank');
      } else {
        throw new Error('Generated PDF is not a Blob');
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF. Please try again.');
    }
  };
  const calculateAnnualDebtService = (loan) => {
    const totalLoanAmount = Number(loan.loanSize || 0);
    const sba7aPercentage = Number(loan.loanStructure?.sba7aLoan || 0) / 100;
    const sba7aLoanAmount = totalLoanAmount * sba7aPercentage;
    const interestRate = 0.105; // 10.5% interest rate
    const loanTerm = 10; // 10-year term

    if (sba7aLoanAmount <= 0) {
      return 0;
    }

    const monthlyRate = interestRate / 12;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment = (sba7aLoanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    
    return monthlyPayment * 12;
  };
  const handleDeductionToggle = (year, deductionKey, deductionValue) => {
    setSelectedDeductions(prevDeductions => ({
      ...prevDeductions,
      [year]: {
        ...prevDeductions[year],
        [deductionKey]: prevDeductions[year][deductionKey] ? undefined : deductionValue
      }
    }));
  };

  const handleDocumentClick = async (document) => {
    try {
      const ocrMetadataRef = collection(db, 'OCRMetadata');
      const q = query(ocrMetadataRef, where('documentId', '==', document.id));
      const querySnapshot = await getDocs(q);
      const ocrMetadata = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ocrResult: replaceNullsWithEmptyStrings(doc.data().ocrResult),
        ownerId: doc.data().ownerId, // Add this line to include ownerId
      }));
  
      if (ocrMetadata.length > 0 && ocrMetadata[0].ocrResult) {
        setSelectedDocument({ ...document, ocrMetadata });
      } else {
        console.warn('No OCR metadata found for document:', document);
        setSelectedDocument({ ...document, ocrMetadata: [] });
      }
    } catch (error) {
      console.error('Error fetching OCR metadata:', error);
      setSelectedDocument({ ...document, ocrMetadata: [] });
    }
  };

  const handleOcrInputChange = (e, index, key) => {
    const value = e.target.value;
    setSelectedDocument((prevDocument) => {
      const newOcrMetadata = [...prevDocument.ocrMetadata];
      newOcrMetadata[index].ocrResult[key] = value;
      return {
        ...prevDocument,
        ocrMetadata: newOcrMetadata,
      };
    });
  };

  const handleOtherDeductionsChange = (e, index, key) => {
    const value = e.target.value;
    setDeductions((prevDeductions) =>
      prevDeductions.map((deduction, i) =>
        i === index ? { ...deduction, [key]: value } : deduction
      )
    );
  };

  const handleSaveFinancials = async (financials) => {
    try {
      const loanRef = doc(db, 'loanApplications', loanId);
      const loanSnapshot = await getDoc(loanRef);
      if (loanSnapshot.exists()) {
        const loanData = loanSnapshot.data();
        const updatedLoanData = {
          ...loanData,
          financialAnalysis: {
            ...(loanData.financialAnalysis || {}),
            [financials.year]: financials
          }
        };

        await updateDoc(loanRef, updatedLoanData);
        console.log('Financial analysis updated successfully.');
      }
    } catch (error) {
      console.error('Error saving financial analysis:', error);
    }
  };

  const openFileInNewTab = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  const handleSaveChanges = async (loanId, documentId, updatedOcrData, documentLabel) => {
    try {
      console.log('Starting save process for document:', documentLabel);
      console.log('Document ID:', documentId);
      console.log('Updated OCR data:', updatedOcrData);

      // 1. Update OCR metadata
      const ocrMetadataRef = doc(db, 'OCRMetadata', documentId);
      const ocrMetadataSnap = await getDoc(ocrMetadataRef);

      if (!ocrMetadataSnap.exists()) {
        console.log('OCR metadata document does not exist. Creating new document.');
        await setDoc(ocrMetadataRef, { ocrResult: updatedOcrData });
      } else {
        console.log('OCR metadata document exists. Updating document.');
        await updateDoc(ocrMetadataRef, { ocrResult: updatedOcrData });
      }
      console.log('OCR metadata updated successfully.');

      // 2. Update loan application
      const loanRef = doc(db, 'loanApplications', loanId);
      const loanSnapshot = await getDoc(loanRef);
      
      if (loanSnapshot.exists()) {
        const loanData = loanSnapshot.data();
        const updatedLoanData = { ...loanData };
  
        console.log('Updating loan data for document type:', documentLabel);
  
        // Initialize financialAnalysis if it doesn't exist
        if (!updatedLoanData.financialAnalysis) {
          updatedLoanData.financialAnalysis = {};
        }
  
        // Update specific fields based on document type
        if (documentLabel.includes('Business Tax Return')) {
          let yearKey;
          if (documentLabel.includes('Current Year')) {
            yearKey = 'Business Tax Return - Current Year';
          } else if (documentLabel.includes('Last Year')) {
            yearKey = 'Business Tax Return - Last Year';
          } else if (documentLabel.includes('2 Years Ago')) {
            yearKey = 'Business Tax Return - 2 Years Ago';
          }
  
          if (yearKey) {
            // Initialize the year in financialAnalysis if it doesn't exist
            if (!updatedLoanData.financialAnalysis[yearKey]) {
              updatedLoanData.financialAnalysis[yearKey] = {};
            }
  
            // Update the financialAnalysis for this year
            updatedLoanData.financialAnalysis[yearKey] = {
              ...updatedLoanData.financialAnalysis[yearKey],
              ordinaryBusinessIncome: parseFloat(updatedOcrData.ordinary_business_income) || 0,
              grossRevenue: parseFloat(updatedOcrData.gross_receipts_or_sales) || 0,
              officerCompensation: parseFloat(updatedOcrData.compensation_of_officers) || 0,
              interest: parseFloat(updatedOcrData.interest) || 0,
              depreciation: parseFloat(updatedOcrData.depreciation) || 0,
              year: yearKey,
            };

            // Calculate NOI and DSCR
            const sde = updatedLoanData.financialAnalysis[yearKey].ordinaryBusinessIncome +
                      updatedLoanData.financialAnalysis[yearKey].officerCompensation +
                      updatedLoanData.financialAnalysis[yearKey].interest +
                      updatedLoanData.financialAnalysis[yearKey].depreciation;
            
            const newOfficerCompensation = 80000 * (loan.personalGuarantors?.length || 1); // Assuming $80,000 per guarantor
            const noi = sde - newOfficerCompensation;
            
            updatedLoanData.financialAnalysis[yearKey].noi = noi;
            updatedLoanData.financialAnalysis[yearKey].newOfficerCompensation = newOfficerCompensation;

            // Calculate DSCR (you may need to adjust this based on your specific DSCR calculation)
            const annualDebtService = calculateAnnualDebtService(loan); // You'll need to implement this function
            updatedLoanData.financialAnalysis[yearKey].dscr = noi / annualDebtService;

            // Handle other deductions
            if (updatedOcrData.other_deductions) {
              const otherDeductions = {};
              if (Array.isArray(updatedOcrData.other_deductions)) {
                updatedOcrData.other_deductions.forEach(deduction => {
                  otherDeductions[deduction.category] = parseFloat(deduction.amount) || 0;
                });
              } else if (typeof updatedOcrData.other_deductions === 'object') {
                Object.entries(updatedOcrData.other_deductions).forEach(([category, amount]) => {
                  otherDeductions[category] = parseFloat(amount) || 0;
                });
              }
              updatedLoanData.financialAnalysis[yearKey].otherDeductions = otherDeductions;
            }
  
            console.log(`Updated Business Tax Return data for ${yearKey}`);
          } else {
            console.log('Unable to determine year for Business Tax Return');
          }
        } else if (documentLabel === 'Credit Bureau Report') {
          updatedLoanData.CreditScore = parseInt(updatedOcrData.CreditScore) || 0;
          console.log('Updated Credit Score');
        } else if (documentLabel === 'Personal Financial Statement') {
          updatedLoanData.totalAssets = parseFloat(updatedOcrData.totalAssets) || 0;
          updatedLoanData.totalLiabilities = parseFloat(updatedOcrData.totalLiabilities) || 0;
          updatedLoanData.netWorth = parseFloat(updatedOcrData.netWorth) || 0;
          console.log('Updated Personal Financial Statement data');
        } else if (documentLabel === 'Resumes for Key Team Members') {
          updatedLoanData.resumeScore = parseInt(updatedOcrData.resumeScore) || 0;
          console.log('Updated Resume Score');
        } else {
          console.log('No specific updates for document type:', documentLabel);
        }
  
        console.log('Attempting to update loan application with:', updatedLoanData);
        await updateDoc(loanRef, updatedLoanData);
        console.log('Loan application updated successfully.');
  
        // 3. Update local state
        setLoan(prevLoan => ({
          ...prevLoan,
          ...updatedLoanData
        }));
        console.log('Local loan state updated');
  
        // 4. Refresh the document data
        const updatedDocuments = await fetchDocuments(loanId);
        setDocumentsByLabel(updatedDocuments);
        console.log('Document data refreshed');
  
        // 5. Update selected document
        setSelectedDocument(prevDoc => ({
          ...prevDoc,
          ocrMetadata: [{
            ...prevDoc.ocrMetadata[0],
            ocrResult: updatedOcrData
          }]
        }));
        console.log('Selected document updated');
  
        console.log('Changes saved successfully.');
      } else {
        throw new Error('Loan application not found');
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      throw error; // Re-throw the error to be caught in the LoanFiles component
    }
  };

  const handleAssignBank = async (bankId) => {
    setAssigningBankId(bankId);
    setIsDropboxModalOpen(true);
  };

  const handleDropboxLinkSubmit = async () => {
    if (!dropboxLink || !assigningBankId || !loanId) {
      console.error('Missing required information');
      return;
    }

    try {
      console.log('Starting bank assignment process for bank ID:', assigningBankId);
      
      // Update loan application
      const loanRef = doc(db, 'loanApplications', loanId);
      await updateDoc(loanRef, {
        assignedBank: assigningBankId
      });
      console.log('Loan application updated with assigned bank');

      // Create bank assignment
      const now = new Date();
      const assignmentData = {
        bankId: assigningBankId,
        applicationId: loanId,
        assignedAt: serverTimestamp(),
        currentStage: {
          name: 'underReview',
          enteredAt: serverTimestamp()
        },
        stageHistory: [{
          name: 'underReview',
          enteredAt: now.toISOString(),
          exitedAt: null
        }],
        dropboxLink: dropboxLink
      };
      const assignmentRef = await addDoc(collection(db, 'bankAssignments'), assignmentData);
      console.log(`Bank assignment created with ID: ${assignmentRef.id}`);

      // Fetch bank data and create email
      const bankRef = doc(db, 'banks', assigningBankId);
      const bankSnap = await getDoc(bankRef);
      if (bankSnap.exists()) {
        const bankData = bankSnap.data();
        console.log('Bank data retrieved:', bankData);
        
        console.log('Calling createEmailDocument');
        const emailId = await createEmailDocument(bankData);
        console.log('Email document created successfully with ID:', emailId);

        // Check the created email document
        const emailDocRef = doc(db, 'email', emailId);
        const emailDocSnap = await getDoc(emailDocRef);
        if (emailDocSnap.exists()) {
          console.log('Email document data:', emailDocSnap.data());
        } else {
          console.log('Email document does not exist');
        }
      } else {
        console.error('Bank not found:', assigningBankId);
      }

      console.log(`Application ${loanId} assigned to bank with ID ${assigningBankId}.`);
      alert('Bank assigned successfully!');
      setIsDropboxModalOpen(false);
      setDropboxLink('');
      setAssigningBankId(null);

      // Refresh the loan details after assignment
      const updatedLoanSnap = await getDoc(loanRef);
      if (updatedLoanSnap.exists()) {
        setLoan({ id: updatedLoanSnap.id, ...updatedLoanSnap.data() });
      }

      // Refresh the bank assignments
      await fetchBankAssignments();
    } catch (error) {
      console.error('Error in handleDropboxLinkSubmit:', error);
      console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      alert('Failed to assign bank. Please check the console for error details.');
    }
  };

  const createEmailDocument = async (bankData) => {
    console.log('createEmailDocument called with bankData:', bankData);
    try {
      console.log('Creating email document for bank:', bankData.bankName);
      const emailRef = collection(db, 'email');
      console.log('Email collection reference created');

      // Generate the summary PDF
      const pdfBlob = await generateSummaryPDF(loan);
      
      // Upload the PDF to Firebase Storage
      const storageRef = ref(storage, `loan_summaries/${loanId}_summary.pdf`);
      await uploadBytesResumable(storageRef, pdfBlob);
      
      // Get the download URL for the uploaded PDF
      const pdfUrl = await getDownloadURL(storageRef);
  
      const loginUrl = "https://lended.ai/login"; // Replace with your actual login URL
  
      const emailData = {
        to: bankData.email,
        from: "max@sbacapital.ai",
        replyTo: "max@sbacapital.ai",
        subject: "You've been matched with a loan!",
        templateName: "LoanMatch",
        message: {
          subject: "You've been matched with a loan!",
          firstName: bankData.loanOfficerName,
          loanDetails: "A new loan application has been matched with your bank in the loan portal. Please log in to review the details.",
          html: `<p1>Hey ${bankData.loanOfficerName},</p1>
  
  <p>A new loan application has been matched with your bank in the loan portal. Please <a href="${loginUrl}">log in</a> to review the details.</p>
  
  <p>We've prepared a summary of the loan application for your convenience. You can view it <a href="${pdfUrl}">here</a>.</p>
  
  <p>Looking forward to hearing back from you!</p>
   
  <p>Best,</p>
  <p>Max Krakow</p>
  <p>Lended</p>
  `,
        },
      };
      console.log('Email data prepared:', JSON.stringify(emailData, null, 2));
  
      const emailDoc = await addDoc(emailRef, emailData);
      console.log('Email document created with ID:', emailDoc.id);
      return emailDoc.id;
    } catch (error) {
      console.error('Error creating email document:', error);
      console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      throw error;
    }
  };

  const renderDocumentStatus = (documentName, isIndividual) => {
    if (isIndividual) {
      return personalGuarantors.every((guarantor) =>
        uploadedDocuments.some(
          (doc) =>
            doc.documentName === documentName &&
            doc.ownerId === `${guarantor.firstName} ${guarantor.lastName}`
        )
      );
    } else {
      return uploadedDocuments.some(
        (doc) => doc.documentName === documentName && doc.ownerId === null
      );
    }
  };

  const renderProgressDetails = (stepName) => {
    const { documentStatus } = checkDocumentProgress();

    if (stepName === 'Pre-Check') {
      const preCheckDocs = documentRequirements.filter((doc) => doc.Individual);

      return (
        <div className="mt-4">
          <h4 className="text-sm font-medium text-gray-900">Document Progress</h4>
          <ul className="mt-2 space-y-2">
            {preCheckDocs.map((doc) => (
              <li key={doc.DocumentID} className="flex items-center">
                {documentStatus[doc.Name] ? (
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                ) : (
                  <MinusCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                )}
                <span className="text-sm text-gray-700">{doc.Name}</span>
              </li>
            ))}
            <li className="flex items-center">
              {loan.creditScores && Object.values(loan.creditScores).some(score => score > 0) ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
              ) : (
                <MinusCircleIcon className="h-5 w-5 text-red-500 mr-2" />
              )}
              <span className="text-sm text-gray-700">Credit Score</span>
            </li>
          </ul>
        </div>
      );
    } else if (stepName === 'Loan Application') {
      const requiredFields = [
        { name: 'Business Age', field: 'businessAge' },
        { name: 'Business State', field: 'businessState' },
        { name: 'Business Summary', field: 'businessSummary' },
        { name: 'EBITDA', field: 'ebitda' },
        { name: 'Loan Size', field: 'loanSize' },
        { name: 'Real Estate Appraisal', field: 'realEstateAppraisal' },
        { name: 'Revenue', field: 'revenue' }
      ];

      return (
        <div className="mt-4">
          <h4 className="text-sm font-medium text-gray-900">Application Status</h4>
          <ul className="mt-2 space-y-2">
            {requiredFields.map((item) => (
              <li key={item.field} className="flex items-center">
                {loan[item.field] ? (
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                ) : (
                  <MinusCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                )}
                <span className="text-sm text-gray-700">{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (stepName === 'Term Sheet') {
      const businessDocs = documentRequirements.filter((doc) => !doc.Individual);

      return (
        <div className="mt-4">
          <h4 className="text-sm font-medium text-gray-900">Document Progress</h4>
          <ul className="mt-2 space-y-2">
            {businessDocs.map((doc) => (
              <li key={doc.DocumentID} className="flex items-center">
                {documentStatus[doc.Name] ? (
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                ) : (
                  <MinusCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                )}
                <span className="text-sm text-gray-700">{doc.Name}</span>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };

  const getCreditScore = (loan) => {
    if (!loan || !loan.creditScores) {
      return 'N/A';
    }
    const scores = Object.values(loan.creditScores);
    return scores.length > 0 ? Math.max(...scores.map(score => parseInt(score) || 0)) : 'N/A';
  };

  const getDSCR = (loan, year) => {
    return loan?.financialAnalysis?.[year]?.dscr?.toFixed(2) || 'N/A';
  };

  if (!loan) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  // Render functions for different document types
  const renderPersonalFinancialStatement = (ocrResult, index) => {
    return (
      <div className="bg-gray-100 p-4 rounded mb-4">
        {Object.entries(ocrResult).map(([key, value]) => {
          if (typeof value === 'object' && !Array.isArray(value)) {
            return (
              <div key={key} className="mb-4">
                <h5 className="text-lg font-medium mb-2">{key}</h5>
                {Object.entries(value).map(([subKey, subValue]) => (
                  <div key={subKey} className="flex items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700 mr-2">{subKey}</label>
                    <input
                      type="text"
                      value={subValue}
                      onChange={(e) => handleOcrInputChange(e, index, `${key}.${subKey}`)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                ))}
              </div>
            );
          } else if (Array.isArray(value)) {
            return (
              <div key={key} className="mb-4">
                <h5 className="text-lg font-medium mb-2">{key}</h5>
                {value.map((item, subIndex) => (
                  <div key={subIndex} className="mb-2">
                    {Object.entries(item).map(([subKey, subValue]) => (
                      <div key={subKey} className="flex items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700 mr-2">{subKey}</label>
                        <input
                          type="text"
                          value={subValue}
                          onChange={(e) => handleOcrInputChange(e, index, `${key}.${subIndex}.${subKey}`)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            );
          } else {
            return (
              <div key={key} className="flex items-center mb-2">
                <label className="block text-sm font-medium text-gray-700 mr-2">{key}</label>
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleOcrInputChange(e, index, key)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderCreditBureauReport = (ocrResult, index) => {
    return (
      <div className="bg-gray-100 p-4 rounded mb-4">
        <div className="flex items-center mb-2">
          <label className="block text-sm font-medium text-gray-700 mr-2">Credit Score</label>
          <input
            type="text"
            value={ocrResult.CreditScore}
            onChange={(e) => handleOcrInputChange(e, index, 'CreditScore')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    );
  };

  const renderBankStatement = (ocrResult, index) => {
    return (
      <div className="bg-gray-100 p-4 rounded mb-4">
        <div className="flex items-center mb-2">
          <label className="block text-sm font-medium text-gray-700 mr-2">Balance</label>
          <input
            type="text"
            value={ocrResult.Balance}
            onChange={(e) => handleOcrInputChange(e, index, 'Balance')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    );
  };

  const renderPersonalTaxReturns = (ocrResult, index) => {
    return (
      <div className="bg-gray-100 p-4 rounded mb-4">
        <div className="flex items-center mb-2">
          <label className="block text-sm font-medium text-gray-700 mr-2">Salary</label>
          <input
            type="text"
            value={ocrResult.Salary}
            onChange={(e) => handleOcrInputChange(e, index, 'Salary')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    );
  };

  const renderBusinessTaxReturns = (ocrResult, index) => {
    const priorityFields = ['compensation_of_officers', 'ordinary_business_income', 'gross_receipts_or_sales'];
    const year = selectedDocument.label;
    
    const renderField = (key, value) => (
      <div key={key} className="flex items-center mb-2">
        <label className="block text-sm font-medium text-gray-700 mr-2">
          {key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}:
        </label>
        <input
          type="text"
          value={value || ''}
          onChange={(e) => handleOcrInputChange(e, index, key)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    );
  
    const renderOtherDeductions = (deductions) => {
      if (Array.isArray(deductions)) {
        return deductions.map((deduction, i) => (
          <div key={i} className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={!!selectedDeductions[year]?.[deduction.category]}
              onChange={() => handleDeductionToggle(year, deduction.category, Number(deduction.amount))}
              className="mr-2"
            />
            <label className="block text-sm font-medium text-gray-700 mr-2">{deduction.category}:</label>
            <input
              type="number"
              value={deduction.amount || 0}
              onChange={(e) => {
                const newAmount = e.target.value;
                handleOcrInputChange(e, index, `other_deductions.${i}.amount`);
                if (selectedDeductions[year]?.[deduction.category]) {
                  handleDeductionToggle(year, deduction.category, Number(newAmount));
                }
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        ));
      } else if (typeof deductions === 'object' && deductions !== null) {
        return Object.entries(deductions).map(([category, amount]) => (
          <div key={category} className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={!!selectedDeductions[year]?.[category]}
              onChange={() => handleDeductionToggle(year, category, Number(amount))}
              className="mr-2"
            />
            <label className="block text-sm font-medium text-gray-700 mr-2">{category}:</label>
            <input
              type="number"
              value={amount || 0}
              onChange={(e) => {
                const newAmount = e.target.value;
                handleOcrInputChange(e, index, `other_deductions.${category}`);
                if (selectedDeductions[year]?.[category]) {
                  handleDeductionToggle(year, category, Number(newAmount));
                }
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        ));
      }
      return <div>No deductions data available</div>;
    };
  
    return (
      <div className="bg-gray-100 p-4 rounded mb-4">
        {/* Priority fields */}
        {priorityFields.map(field => ocrResult[field] !== undefined && renderField(field, ocrResult[field]))}
  
        {/* Other fields (excluding priority fields and other_deductions) */}
        {Object.entries(ocrResult)
          .filter(([key]) => !priorityFields.includes(key) && key !== 'other_deductions')
          .map(([key, value]) => renderField(key, value))}
  
        {/* Other Deductions */}
        {ocrResult.other_deductions && (
          <div className="mt-4">
            <h5 className="text-lg font-medium mb-2">Other Deductions</h5>
            {renderOtherDeductions(ocrResult.other_deductions)}
          </div>
        )}
      </div>
    );
  };

  const renderResumes = (ocrResult, index) => {
    return (
      <div className="bg-gray-100 p-4 rounded mb-4">
        {ocrResult.experiences.map((experience, i) => (
          <div key={i} className="mb-4">
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-2">Company</label>
              <input
                type="text"
                value={experience.company}
                onChange={(e) => handleOcrInputChange(e, index, `experiences.${i}.company`)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-2">Duration</label>
              <input
                type="text"
                value={experience.duration}
                onChange={(e) => handleOcrInputChange(e, index, `experiences.${i}.duration`)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-2">Position</label>
              <input
                type="text"
                value={experience.position}
                onChange={(e) => handleOcrInputChange(e, index, `experiences.${i}.position`)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-2">Summary</label>
              <input
                type="text"
                value={experience.summary}
                onChange={(e) => handleOcrInputChange(e, index, `experiences.${i}.summary`)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };
  const renderFinancialItem = (label, value, isBold = false) => (
    <div className={`py-2 sm:grid sm:grid-cols-3 sm:gap-4 ${isBold ? 'font-bold' : ''}`}>
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatCurrency(value)}</dd>
    </div>
  );
  const renderFinancialDetails = (data) => {
    const sections = [
      { title: 'Assets', items: data.assets },
      { title: 'Liabilities', items: data.liabilities }
    ];
  
    return sections.map((section) => (
      <div key={section.title} className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{section.title}</h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            {Object.entries(section.items || {}).map(([key, value]) => (
              <div key={key} className={`px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ${
                Object.entries(section.items || {}).indexOf([key, value]) % 2 === 0 ? 'bg-gray-50' : 'bg-white'
              }`}>
                <dt className="text-sm font-medium text-gray-500">{key}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatCurrency(value)}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    ));
  };
  const renderPersonalFinancialInfo = () => {
    if (personalFinancialData.length === 0 || !loan || !loan.personalGuarantors) {
      return <div>No personal financial data available.</div>;
    }
  
    return (
      <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Guarantor's Personal Financial Information</h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <div className="sm:divide-y sm:divide-gray-200">
            {/* Tabs for multiple guarantors */}
            <div className="mb-4 border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {loan.personalGuarantors.map((guarantor, index) => (
                  <button
                    key={index}
                    onClick={() => setActiveGuarantorIndex(index)}
                    className={`${
                      index === activeGuarantorIndex
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                  >
                    {`${guarantor.firstName} ${guarantor.lastName}`}
                  </button>
                ))}
              </nav>
            </div>
            {/* Render active guarantor's data */}
            {personalFinancialData[activeGuarantorIndex] && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                <div>
                  <h4 className="text-md font-medium text-gray-900 mb-2">Assets</h4>
                  {renderFinancialItem("Cash on Hand", personalFinancialData[activeGuarantorIndex].Assets.CashOnHandAndInBanks)}
                  {renderFinancialItem("Savings Accounts", personalFinancialData[activeGuarantorIndex].Assets.SavingsAccounts)}
                  {renderFinancialItem("Stocks and Bonds", personalFinancialData[activeGuarantorIndex].Assets.StocksAndBonds)}
                  {renderFinancialItem("Real Estate", personalFinancialData[activeGuarantorIndex].Assets.RealEstate)}
                  {renderFinancialItem("Automobiles", personalFinancialData[activeGuarantorIndex].Assets.Automobiles)}
                  {renderFinancialItem("Other Assets", personalFinancialData[activeGuarantorIndex].Assets.OtherAssets)}
                  {renderFinancialItem("Total Assets", personalFinancialData[activeGuarantorIndex].Assets.Total, true)}
                </div>
                <div>
                  <h4 className="text-md font-medium text-gray-900 mb-2">Liabilities</h4>
                  {renderFinancialItem("Accounts Payable", personalFinancialData[activeGuarantorIndex].Liabilities.AccountsPayable)}
                  {renderFinancialItem("Notes Payable", personalFinancialData[activeGuarantorIndex].Liabilities.NotesPayableToBanksAndOthers)}
                  {renderFinancialItem("Auto Installment", personalFinancialData[activeGuarantorIndex].Liabilities.InstallmentAccountAuto)}
                  {renderFinancialItem("Other Installment", personalFinancialData[activeGuarantorIndex].Liabilities.InstallmentAccountOther)}
                  {renderFinancialItem("Life Insurance Loans", personalFinancialData[activeGuarantorIndex].Liabilities.LoanAgainstLifeInsurance)}
                  {renderFinancialItem("Mortgages", personalFinancialData[activeGuarantorIndex].Liabilities.MortgagesOnRealEstate)}
                  {renderFinancialItem("Unpaid Taxes", personalFinancialData[activeGuarantorIndex].Liabilities.UnpaidTaxes)}
                  {renderFinancialItem("Other Liabilities", personalFinancialData[activeGuarantorIndex].Liabilities.OtherLiabilities)}
                  {renderFinancialItem("Total Liabilities", personalFinancialData[activeGuarantorIndex].Liabilities.TotalLiabilities, true)}
                </div>
              </div>
            )}
            <div className="py-4 sm:py-5 sm:px-6">
              <div className="text-lg font-bold">
                Net Worth: {formatCurrency(calculateNetWorth(personalFinancialData[activeGuarantorIndex]))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  };
  const renderOcrMetadata = (ocrMetadata, documentName, index) => {
    switch (documentName) {
      case 'Personal Financial Statement':
        return renderPersonalFinancialStatement(ocrMetadata, index);
      case 'Credit Bureau Report':
        return renderCreditBureauReport(ocrMetadata.ocrResult, index);
      case 'Bank Statement for Equity Injection':
        return renderBankStatement(ocrMetadata.ocrResult, index);
      case 'Personal Tax Returns':
        return renderPersonalTaxReturns(ocrMetadata.ocrResult, index);
      case 'Business Tax Returns':
        return renderBusinessTaxReturns(ocrMetadata.ocrResult, index);
      case 'Resumes for Key Team Members':
        return renderResumes(ocrMetadata.ocrResult, index);
      default:
        return null;
    }
  };

  // Separate documents into personal and non-personal categories
  const personalDocuments = {};
  const nonPersonalDocuments = {};

  Object.entries(documentsByLabel).forEach(([label, documents]) => {
    const requirement = documentRequirements.find((req) => req.Labels.includes(label));
    if (requirement && requirement.Individual) {
      documents.forEach((document) => {
        if (!personalDocuments[document.ownerId]) {
          personalDocuments[document.ownerId] = [];
        }
        personalDocuments[document.ownerId].push({ label, document });
      });
    } else {
      if (!nonPersonalDocuments[label]) {
        nonPersonalDocuments[label] = [];
      }
      nonPersonalDocuments[label].push(...documents);
    }
  });

  const toggleSection = (sectionName) => {
    setExpandedSections(prev => ({ ...prev, [sectionName]: !prev[sectionName] }));
  };

  const getFinancialData = (key) => {
    const years = ['2 Years Ago', 'Last Year', 'Current Year'];
    return years.map(year => ({
      name: year,
      value: parseFloat(loan?.financialAnalysis?.[`Business Tax Return - ${year}`]?.[key] || 0)
    }));
  };

  const GRAPH_COLORS = ['#008081', '#6593F5', '#3FE0D0'];

  const FinancialStatCard = ({ item }) => (
    <div className="overflow-hidden rounded-lg bg-white px-6 py-8 shadow sm:p-8">
      <dt className="truncate text-lg font-medium text-gray-500">{item.name}</dt>
      <dd className="mt-2 flex flex-col">
        <div className="flex items-baseline justify-between">
          <div className="flex items-baseline text-3xl font-semibold text-black">
            {item.stat}
            <span className="ml-2 text-base font-medium text-gray-500">from {item.previousStat}</span>
          </div>
          <div
            className={classNames(
              item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
              'inline-flex items-baseline rounded-full px-3 py-1 text-base font-medium'
            )}
          >
            {item.changeType === 'increase' ? (
              <ArrowUpIcon className="-ml-1 mr-0.5 h-6 w-6 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
            ) : (
              <ArrowDownIcon className="-ml-1 mr-0.5 h-6 w-6 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
            )}
            <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
            {item.change}
          </div>
        </div>
        <div className="mt-6 h-48">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={item.data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill={item.color} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </dd>
    </div>
  );

  const financialStats = [
    { 
      name: 'DSCR', 
      stat: loan?.financialAnalysis?.['Business Tax Return - Current Year']?.dscr?.toFixed(2) || 'N/A', 
      previousStat: loan?.financialAnalysis?.['Business Tax Return - Last Year']?.dscr?.toFixed(2) || 'N/A', 
      change: ((loan?.financialAnalysis?.['Business Tax Return - Current Year']?.dscr / loan?.financialAnalysis?.['Business Tax Return - Last Year']?.dscr - 1) * 100).toFixed(2) + '%' || 'N/A',
      changeType: loan?.financialAnalysis?.['Business Tax Return - Current Year']?.dscr > loan?.financialAnalysis?.['Business Tax Return - Last Year']?.dscr ? 'increase' : 'decrease',
      data: getFinancialData('dscr'),
      color: GRAPH_COLORS[0]
    },
    { 
      name: 'Net Operating Income', 
      stat: `$${loan?.financialAnalysis?.['Business Tax Return - Current Year']?.noi?.toLocaleString() || 'N/A'}`, 
      previousStat: `$${loan?.financialAnalysis?.['Business Tax Return - Last Year']?.noi?.toLocaleString() || 'N/A'}`, 
      change: ((loan?.financialAnalysis?.['Business Tax Return - Current Year']?.noi / loan?.financialAnalysis?.['Business Tax Return - Last Year']?.noi - 1) * 100).toFixed(2) + '%' || 'N/A',
      changeType: loan?.financialAnalysis?.['Business Tax Return - Current Year']?.noi > loan?.financialAnalysis?.['Business Tax Return - Last Year']?.noi ? 'increase' : 'decrease',
      data: getFinancialData('noi'),
      color: GRAPH_COLORS[1]
    },
    { 
      name: 'Ordinary Business Income', 
      stat: `$${loan?.financialAnalysis?.['Business Tax Return - Current Year']?.ordinaryBusinessIncome?.toLocaleString() || 'N/A'}`, 
      previousStat: `$${loan?.financialAnalysis?.['Business Tax Return - Last Year']?.ordinaryBusinessIncome?.toLocaleString() || 'N/A'}`, 
      change: ((loan?.financialAnalysis?.['Business Tax Return - Current Year']?.ordinaryBusinessIncome / loan?.financialAnalysis?.['Business Tax Return - Last Year']?.ordinaryBusinessIncome - 1) * 100).toFixed(2) + '%' || 'N/A',
      changeType: loan?.financialAnalysis?.['Business Tax Return - Current Year']?.ordinaryBusinessIncome > loan?.financialAnalysis?.['Business Tax Return - Last Year']?.ordinaryBusinessIncome ? 'increase' : 'decrease',
      data: getFinancialData('ordinaryBusinessIncome'),
      color: GRAPH_COLORS[2]
    },
  ];

  if (!loan) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <nav className="flex items-center" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <button
                        onClick={() => navigate(-1)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <ArrowLeftIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">Back</span>
                      </button>
                    </div>
                  </li>
                  <li className="flex items-center">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    {`${loan?.firstName || ''} ${loan?.lastName || ''}`.trim() || 'User'}'s Loan Details
                    </h2>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <button
                type="button"
                onClick={handleGenerateSummaryPDF}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <DocumentTextIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                Summary Document
              </button>
            </div>
          </div>
        </div>
      </header>
  
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Updated Progress Bar with Details */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-6 py-4 border-b border-gray-200">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Loan Application Progress</h3>
            </div>
            <div className="px-6 py-4">
              <nav aria-label="Progress">
                <ol className="flex items-start justify-between w-full">
                  {steps.map((step, stepIdx) => {
                    const { preCheckComplete, businessDocumentsComplete, formComplete, creditScoreComplete } = checkDocumentProgress();
                    let status = 'upcoming';
                    
                    if (step.name === 'Pre-Check') {
                      status = preCheckComplete && creditScoreComplete ? 'complete' : 'upcoming';
                    } else if (step.name === 'Loan Application') {
                      status = formComplete ? 'complete' : (preCheckComplete && creditScoreComplete ? 'current' : 'upcoming');
                    } else if (step.name === 'Term Sheet') {
                      status = businessDocumentsComplete ? 'complete' : (formComplete ? 'current' : 'upcoming');
                    }
  
                    return (
                      <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
                        {/* ... existing step rendering ... */}
                        <div className="mt-2">
                          <span className="block text-sm font-medium text-gray-900">{step.name}</span>
                          <span className="block text-sm text-gray-500">{step.description}</span>
                          {renderProgressDetails(step.name)}
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </nav>
            </div>
          </div>
  
          {/* Financial Metrics Display */}
          <div className="mb-8">
            {loan && Object.keys(ocrData).length > 0 ? (
              <FinancialMetricsDisplayAdmin 
                ocrData={ocrData}
                loanApplication={loan}
              />
            ) : (
              <p>Loading financial data...</p>
            )}
          </div>
         
          {/* Personal Financial Statement Section */}
          <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Guarantor's Personal Financial Information</h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <div className="sm:divide-y sm:divide-gray-200">
                {/* Tabs for multiple guarantors */}
                {loan?.personalGuarantors && loan.personalGuarantors.length > 1 && (
                  <div className="mb-4 border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {loan.personalGuarantors.map((guarantor, index) => (
                        <button
                          key={index}
                          onClick={() => setActiveGuarantorIndex(index)}
                          className={`${
                            index === activeGuarantorIndex
                              ? 'border-blue-500 text-blue-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                          } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                        >
                          {`${guarantor.firstName} ${guarantor.lastName}`}
                        </button>
                      ))}
                    </nav>
                  </div>
                )}
                 {/* Render active guarantor's data */}
                 {personalFinancialData[activeGuarantorIndex] && (
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <div>
                      <h4 className="text-md font-medium text-gray-900 mb-2">Assets</h4>
                      {renderFinancialItem("Cash on Hand", personalFinancialData[activeGuarantorIndex].Assets.CashOnHandAndInBanks)}
                      {renderFinancialItem("Savings Accounts", personalFinancialData[activeGuarantorIndex].Assets.SavingsAccounts)}
                      {renderFinancialItem("Stocks and Bonds", personalFinancialData[activeGuarantorIndex].Assets.StocksAndBonds)}
                      {renderFinancialItem("Real Estate", personalFinancialData[activeGuarantorIndex].Assets.RealEstate)}
                      {renderFinancialItem("Automobiles", personalFinancialData[activeGuarantorIndex].Assets.Automobiles)}
                      {renderFinancialItem("Other Assets", personalFinancialData[activeGuarantorIndex].Assets.OtherAssets)}
                      {renderFinancialItem("Total Assets", personalFinancialData[activeGuarantorIndex].Assets.Total, true)}
                    </div>
                    <div>
                      <h4 className="text-md font-medium text-gray-900 mb-2">Liabilities</h4>
                      {renderFinancialItem("Accounts Payable", personalFinancialData[activeGuarantorIndex].Liabilities.AccountsPayable)}
                      {renderFinancialItem("Notes Payable", personalFinancialData[activeGuarantorIndex].Liabilities.NotesPayableToBanksAndOthers)}
                      {renderFinancialItem("Auto Installment", personalFinancialData[activeGuarantorIndex].Liabilities.InstallmentAccountAuto)}
                      {renderFinancialItem("Other Installment", personalFinancialData[activeGuarantorIndex].Liabilities.InstallmentAccountOther)}
                      {renderFinancialItem("Life Insurance Loans", personalFinancialData[activeGuarantorIndex].Liabilities.LoanAgainstLifeInsurance)}
                      {renderFinancialItem("Mortgages", personalFinancialData[activeGuarantorIndex].Liabilities.MortgagesOnRealEstate)}
                      {renderFinancialItem("Unpaid Taxes", personalFinancialData[activeGuarantorIndex].Liabilities.UnpaidTaxes)}
                      {renderFinancialItem("Other Liabilities", personalFinancialData[activeGuarantorIndex].Liabilities.OtherLiabilities)}
                      {renderFinancialItem("Total Liabilities", personalFinancialData[activeGuarantorIndex].Liabilities.TotalLiabilities, true)}
                    </div>
                  </div>
                )}
                <div className="py-4 sm:py-5 sm:px-6">
                  <div className="text-lg font-bold">
                    Net Worth: {formatCurrency(calculateNetWorth(personalFinancialData[activeGuarantorIndex]))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Lender Match Card */}
          <div className="mt-8">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="px-6 py-4 border-b border-gray-200">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Matched Lenders</h3>
              </div>
              <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {matchedLenders.length > 0 ? (
                    matchedLenders.map((lender, index) => {
                      const isAssigned = assignedBanks[lender.id];
                      return (
                        <div key={lender.id} className={`rounded-lg overflow-hidden ${isAssigned ? 'bg-amber-50 border border-amber-200' : 'bg-white border border-gray-200'} shadow-md transition-all duration-300 hover:shadow-lg`}>
                          <div className="p-4">
                            <div className="flex justify-between items-center mb-3">
                              <h4 className="text-lg font-semibold text-gray-800 truncate">{lender.bankName}</h4>
                              {isAssigned ? (
                                <span className="px-2 py-1 bg-amber-100 text-amber-800 rounded-full text-xs font-medium">
                                  Assigned
                                </span>
                              ) : (
                                <button
                                  onClick={() => setAssigningBank(lender.id)}
                                  className="px-3 py-1 bg-blue-500 text-white rounded-full text-xs font-medium hover:bg-blue-600 transition-colors duration-300"
                                >
                                  Assign
                                </button>
                              )}
                            </div>
                            
                            <div className="grid grid-cols-2 gap-3 text-sm mb-3">
                              <div>
                                <p className="text-gray-500">Loan Rate</p>
                                <p className="font-bold text-gray-800">{lender.usualLoanRateMin}% - {lender.usualLoanRateMax}%</p>
                              </div>
                              <div>
                                <p className="text-gray-500">Process Time</p>
                                <p className="font-bold text-gray-800">{lender.processTime || 'N/A'} days</p>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-2 gap-3 text-sm mb-3">
                              <div>
                                <p className="text-gray-500">Approval Rate</p>
                                <p className="font-bold text-gray-800">{lender.approvalPercentage || 'N/A'}%</p>
                              </div>
                              <div>
                                <p className="text-gray-500">Term Sheet Time</p>
                                <p className="font-bold text-gray-800">{lender.termSheetTime || 'N/A'} days</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No matched lenders found.</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Loan Officer Assignment */}
          <div className="mt-8">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="px-6 py-4 border-b border-gray-200">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Assign Loan Officer</h3>
              </div>
              <div className="p-6">
                <LoanOfficerAssignment 
                  loanId={loanId} 
                  handleAssignBank={handleAssignBank} 
                  isPrequalification={false}
                />
              </div>
            </div>
          </div>

          {/* Uploaded Files Section */}
          <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Uploaded Files</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">View or download files uploaded by the applicant.</p>
            </div>
            <div className="border-t border-gray-200">
              <ul role="list" className="divide-y divide-gray-200">
                {Object.entries(documentsByLabel).map(([label, documents]) => (
                  documents.map((doc, index) => (
                    <li key={`${label}-${index}`} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="ml-2 flex-1 w-0 truncate">{doc.fileName || `${label} Document`}</span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <button
                          onClick={() => openFileInNewTab(doc.fileUrl)}
                          className="font-medium text-indigo-600 hover:text-indigo-500 flex items-center"
                        >
                          <ArrowTopRightOnSquareIcon className="h-5 w-5 mr-1" />
                          Open
                        </button>
                      </div>
                    </li>
                  ))
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoanDetails;







