import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const LoanApplicationDisplay = ({ loanApplication = {} }) => {
  const [ocrData, setOcrData] = useState({});
  const [loading, setLoading] = useState(true);
  const [addBacks, setAddBacks] = useState([]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  };

  const getYearFromLabel = (label) => {
    const yearLabels = {
      'Business Tax Return - Current Year': '2023',
      'Business Tax Return - Last Year': '2022',
      'Business Tax Return - 2 Years Ago': '2021'
    };
    return yearLabels[label] || label;
  };

  useEffect(() => {
    const fetchOCRData = async () => {
      if (!loanApplication.id) return;

      try {
        const ocrMetadataRef = collection(db, 'OCRMetadata');
        const q = query(ocrMetadataRef, where('loanApplicationId', '==', loanApplication.id));
        const querySnapshot = await getDocs(q);
        
        const processedData = {};
        let fetchedAddBacks = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.documentName === "Business Tax Returns") {
            const year = getYearFromLabel(data.label);
            processedData[year] = {
              ordinaryBusinessIncome: parseFloat(data.ocrResult?.ordinary_business_income) || 0,
              grossRevenue: parseFloat(data.ocrResult?.gross_receipts_or_sales) || 0,
              officerCompensation: parseFloat(data.ocrResult?.compensation_of_officers) || 0,
              interest: parseFloat(data.ocrResult?.interest) || 0,
              depreciation: parseFloat(data.ocrResult?.depreciation) || 0,
              amortization: parseFloat(data.ocrResult?.amortization) || 0,
              otherDeductions: data.ocrResult?.other_deductions || {},
              year: year,
            };
            if (data.addBacks) {
              fetchedAddBacks = Object.entries(data.addBacks).map(([name, values]) => ({ name, values }));
            }
          }
        });

        setOcrData(processedData);
        setAddBacks(fetchedAddBacks);
      } catch (error) {
        console.error('Error fetching OCR data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOCRData();
  }, [loanApplication.id]);

  const getNumberOfPersonalGuarantors = () => {
    return (loanApplication.personalGuarantors || []).length;
  };

  const calculateNewOfficerCompensation = () => {
    const baseCompensation = 80000; // $80,000 per guarantor
    const numberOfGuarantors = getNumberOfPersonalGuarantors();
    return baseCompensation * numberOfGuarantors;
  };

  const calculateGrossProfit = (yearData) => {
    const grossRevenue = Number(yearData.grossRevenue || 0);
    const cogs = Number(yearData.costOfGoodsSold || 0);
    return grossRevenue - cogs;
  };

  const calculateEBITDAOwnersComp = (yearData) => {
    const baseIncome = Number(yearData.ordinaryBusinessIncome || 0);
    const originalOfficerCompensation = Number(yearData.officerCompensation || 0);
    const interest = Number(yearData.interest || 0);
    const depreciation = Number(yearData.depreciation || 0);
    const amortization = Number(yearData.amortization || 0);
    
    return baseIncome + originalOfficerCompensation + interest + depreciation + amortization;
  };

  const calculateSDE = (yearData) => {
    if (!yearData) return 0;
    const ebitdaOwnersComp = calculateEBITDAOwnersComp(yearData);
    const addbacksTotal = addBacks.reduce((sum, addBack) => sum + Number(addBack.values[yearData.year] || 0), 0);
    
    return ebitdaOwnersComp + addbacksTotal;
  };

  const calculateNOI = (yearData) => {
    if (!yearData) return 0;
    const sde = calculateSDE(yearData);
    const newOfficerCompensation = calculateNewOfficerCompensation();
    return sde - newOfficerCompensation;
  };

  const calculateAnnualDebtService = () => {
    const totalLoanAmount = Number(loanApplication.loanSize || 0);
    const sba7aPercentage = Number(loanApplication.loanStructure?.sba7aLoan || 0) / 100;
    const sba7aLoanAmount = totalLoanAmount * sba7aPercentage;
    const interestRate = 0.105; // 10.5% interest rate
    const loanTerm = 10; // 10-year term

    if (sba7aLoanAmount <= 0) {
      return 0;
    }

    const monthlyRate = interestRate / 12;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment = (sba7aLoanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    
    return monthlyPayment * 12;
  };

  const calculateDSCR = (noi) => {
    const annualDebtService = calculateAnnualDebtService();
    return annualDebtService > 0 ? noi / annualDebtService : 0;
  };

  const years = Object.keys(ocrData).sort((a, b) => b.localeCompare(a));

  const getFinancialData = (key) => {
    return years.map(year => {
      const yearData = ocrData[year] || {};
      let value;
      switch (key) {
        case 'dscr':
          value = calculateDSCR(calculateNOI(yearData));
          break;
        case 'noi':
          value = calculateNOI(yearData);
          break;
        case 'ordinaryBusinessIncome':
          value = yearData.ordinaryBusinessIncome || 0;
          break;
        default:
          value = 0;
      }
      return { name: year, value };
    });
  };

  const totalLoanAmount = Number(loanApplication.loanSize || 0);
  const sba7aPercentage = Number(loanApplication.loanStructure?.sba7aLoan || 0);
  const sba7aLoanAmount = totalLoanAmount * (sba7aPercentage / 100);

  const financialStats = [
    { 
      name: 'DSCR', 
      stat: formatNumber(calculateDSCR(calculateNOI(ocrData[years[0]] || {}))),
      previousStat: formatNumber(calculateDSCR(calculateNOI(ocrData[years[1]] || {}))),
      change: ((calculateDSCR(calculateNOI(ocrData[years[0]] || {})) / calculateDSCR(calculateNOI(ocrData[years[1]] || {})) - 1) * 100).toFixed(2) + '%',
      changeType: calculateDSCR(calculateNOI(ocrData[years[0]] || {})) > calculateDSCR(calculateNOI(ocrData[years[1]] || {})) ? 'increase' : 'decrease',
      data: getFinancialData('dscr'),
      color: '#008081'
    },
    { 
      name: 'Net Operating Income', 
      stat: formatCurrency(calculateNOI(ocrData[years[0]] || {})),
      previousStat: formatCurrency(calculateNOI(ocrData[years[1]] || {})),
      change: ((calculateNOI(ocrData[years[0]] || {}) / calculateNOI(ocrData[years[1]] || {}) - 1) * 100).toFixed(2) + '%',
      changeType: calculateNOI(ocrData[years[0]] || {}) > calculateNOI(ocrData[years[1]] || {}) ? 'increase' : 'decrease',
      data: getFinancialData('noi'),
      color: '#6593F5'
    },
    { 
      name: 'Ordinary Business Income', 
      stat: formatCurrency(ocrData[years[0]]?.ordinaryBusinessIncome || 0),
      previousStat: formatCurrency(ocrData[years[1]]?.ordinaryBusinessIncome || 0),
      change: ((ocrData[years[0]]?.ordinaryBusinessIncome / ocrData[years[1]]?.ordinaryBusinessIncome - 1) * 100).toFixed(2) + '%',
      changeType: ocrData[years[0]]?.ordinaryBusinessIncome > ocrData[years[1]]?.ordinaryBusinessIncome ? 'increase' : 'decrease',
      data: getFinancialData('ordinaryBusinessIncome'),
      color: '#3FE0D0'
    }
  ];

  const FinancialStatCard = ({ item }) => (
    <div className="overflow-hidden rounded-lg bg-white px-6 py-8 shadow sm:p-8">
      <dt className="truncate text-lg font-medium text-gray-500">{item.name}</dt>
      <dd className="mt-2 flex flex-col">
        <div className="flex items-baseline justify-between">
          <div className="flex items-baseline text-3xl font-semibold text-black">
            {item.stat}
            <span className="ml-2 text-base font-medium text-gray-500">from {item.previousStat}</span>
          </div>
          <div
            className={`inline-flex items-baseline rounded-full px-3 py-1 text-base font-medium ${
              item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
            }`}
          >
            {item.changeType === 'increase' ? (
              <svg className="-ml-1 mr-0.5 h-6 w-6 flex-shrink-0 self-center text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
              </svg>
            ) : (
              <svg className="-ml-1 mr-0.5 h-6 w-6 flex-shrink-0 self-center text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            )}
            <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
            {item.change}
          </div>
        </div>
        <div className="mt-6 h-48">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={item.data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill={item.color} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </dd>
    </div>
  );

  const renderLoanStructure = () => {
    const totalLoanAmount = Number(loanApplication.loanSize || 0);
    const sba7aPercentage = Number(loanApplication.loanStructure?.sba7aLoan || 0);
    const sba7aLoanAmount = totalLoanAmount * (sba7aPercentage / 100);
    const equityInjectionPersonal = Number(loanApplication.loanStructure?.equityInjection?.personal || 0) / 100 * totalLoanAmount;
    const equityInjectionSellersNote = Number(loanApplication.loanStructure?.equityInjection?.sellersNote || 0) / 100 * totalLoanAmount;
    const annualDebtService = calculateAnnualDebtService();

    return (
      <div className="mt-8">
        <h3 className="text-xl font-bold mb-4">Loan Structure</h3>
        <table className="min-w-full bg-white border border-gray-300">
          <tbody>
            <tr>
              <td className="px-4 py-2">Total Project Cost</td>
              <td className="px-4 py-2 text-right">{formatCurrency(totalLoanAmount)}</td>
            </tr>
            <tr>
              <td className="px-4 py-2">Equity Injection - Personal</td>
              <td className="px-4 py-2 text-right">{formatCurrency(equityInjectionPersonal)} ({(equityInjectionPersonal / totalLoanAmount * 100).toFixed(2)}%)</td>
            </tr>
            <tr>
              <td className="px-4 py-2">Seller's Note</td>
              <td className="px-4 py-2 text-right">{formatCurrency(equityInjectionSellersNote)} ({(equityInjectionSellersNote / totalLoanAmount * 100).toFixed(2)}%)</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="px-4 py-2 font-bold">Loan Amount</td>
              <td className="px-4 py-2 text-right font-bold">{formatCurrency(sba7aLoanAmount)} ({sba7aPercentage.toFixed(2)}%)</td>
            </tr>
            <tr>
              <td className="px-4 py-2">Annual Debt Service</td>
              <td className="px-4 py-2 text-right">{formatCurrency(annualDebtService)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <div>Loading financial data...</div>
      ) : years.length > 0 ? (
        <>
          <div className="mb-8">
            <h3 className="text-xl font-semibold leading-6 text-gray-900 mb-6">Financial Overview</h3>
            <dl className="mt-5 grid grid-cols-1 gap-8 sm:grid-cols-3">
              {financialStats.map((item) => (
                <FinancialStatCard key={item.name} item={item} />
              ))}
            </dl>
          </div>

          {renderLoanStructure()}

          <h3 className="text-xl font-bold mt-8 mb-4">Financial Analysis</h3>
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 border-b text-left">Metric</th>
                {years.map(year => (
                  <th key={year} className="px-4 py-2 border-b text-right">{year}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[
                { key: 'grossRevenue', label: 'Gross Receipts or Sales' },
                { key: 'costOfGoodsSold', label: 'Cost of Goods Sold', isNegative: true },
                { key: 'grossProfit', label: 'Gross Profit', calculate: calculateGrossProfit, isBold: true, hasBorder: true },
                { key: 'grossProfitMargin', label: 'Gross Profit Margin', calculate: (yearData) => (calculateGrossProfit(yearData) / Number(yearData.grossRevenue || 1)) * 100, isPercentage: true },
                { key: 'ordinaryBusinessIncome', label: 'Ordinary Business Income', isBold: true },
                { key: 'officerCompensation', label: '+ Original Officer Compensation' },
                { key: 'interest', label: '+ Interest' },
                { key: 'depreciation', label: '+ Depreciation' },
                { key: 'amortization', label: '+ Amortization' },
                { key: 'ebitdaOwnersComp', label: 'EBITDA + Owners Comp', calculate: calculateEBITDAOwnersComp, isBold: true, hasBorder: true },
                { key: 'ebitdaOwnersCompPercentage', label: 'EBITDA + Owners Comp %', calculate: (yearData) => (calculateEBITDAOwnersComp(yearData) / Number(yearData.grossRevenue || 1)) * 100, isPercentage: true },
              ].map(({ key, label, calculate, isBold, hasBorder, isNegative, isPercentage }) => (
                <tr key={key} className={`hover:bg-gray-50 ${hasBorder ? 'border-t-2 border-gray-300' : ''}`}>
                  <td className={`px-4 py-2 border-b ${isBold ? 'font-bold' : ''} ${isNegative ? 'text-red-600' : ''}`}>{label}</td>
                  {years.map(year => (
                    <td key={year} className={`px-4 py-2 border-b text-right ${isBold ? 'font-bold' : ''} ${isNegative ? 'text-red-600' : ''}`}>
                      {calculate 
                        ? (isPercentage
                            ? `${formatNumber(calculate(ocrData[year]))}%`
                            : formatCurrency(calculate(ocrData[year])))
                        : (isPercentage
                            ? `${formatNumber((ocrData[year]?.[key] / ocrData[year]?.grossRevenue) * 100 || 0)}%`
                            : formatCurrency(ocrData[year]?.[key] || 0))}
                    </td>
                  ))}
                </tr>
              ))}
              {addBacks.map((addBack) => (
                <tr key={addBack.name}>
                  <td className="px-4 py-2 border-b">+ {addBack.name}</td>
                  {years.map(year => (
                    <td key={year} className="px-4 py-2 border-b text-right">
                      {formatCurrency(Number(addBack.values[year] || 0))}
                    </td>
                  ))}
                </tr>
              ))}
              <tr className="bg-gray-100">
                <td className="px-4 py-2 border-b font-bold">Seller's Discretionary Earnings (SDE)</td>
                {years.map(year => (
                  <td key={year} className="px-4 py-2 border-b text-right font-bold">
                    {formatCurrency(calculateSDE(ocrData[year] || {}))}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="px-4 py-2 border-b">- New Officer Compensation</td>
                {years.map(year => (
                  <td key={year} className="px-4 py-2 border-b text-right">
                    {formatCurrency(-calculateNewOfficerCompensation())}
                  </td>
                ))}
              </tr>
              <tr className="bg-gray-100">
                <td className="px-4 py-2 border-b font-bold">Net Operating Income (NOI)</td>
                {years.map(year => (
                  <td key={year} className="px-4 py-2 border-b text-right font-bold">
                    {formatCurrency(calculateNOI(ocrData[year] || {}))}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="px-4 py-2 border-b">Debt Service Coverage Ratio (DSCR)</td>
                {years.map(year => (
                  <td key={year} className="px-4 py-2 border-b text-right">
                    {formatNumber(calculateDSCR(calculateNOI(ocrData[year] || {})))}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div className="mt-4 text-sm text-gray-600">
            <p>Note: DSCR is calculated based on the SBA 7(a) portion of the loan amount ({sba7aPercentage}% of ${formatNumber(totalLoanAmount)}).</p>
          </div>
        </>
      ) : (
        <p>No financial data available.</p>
      )}
    </div>
  );
};

export default LoanApplicationDisplay;


