import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, addDoc, collection, query, where, getDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage, db } from '../../firebase';
import StateSelect from '../loanForm/StateSelect';
import { getAuth } from 'firebase/auth';

const PrequalifyBusiness = ({ initialData, onSave, onCancel }) => {
  const [formData, setFormData] = useState(initialData || {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const inputRefs = useRef({});
  const [processingStatus, setProcessingStatus] = useState({});

  useEffect(() => {
    setFormData(initialData || {});
    if (initialData && initialData.id) {
      fetchUploadedFiles(initialData.id);
    }
  }, [initialData]);

  const fetchUploadedFiles = async (applicationId) => {
    try {
      const q = query(
        collection(db, 'prequalificationMetadata'),
        where('applicationId', '==', applicationId)
      );
      const querySnapshot = await getDocs(q);
      const files = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUploadedFiles(files);
      console.log('Fetched uploaded files:', files);
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formatCurrency = (value) => {
    if (!value) return '';
    return `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const handleCurrencyChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9.]/g, '');
    setFormData({ ...formData, [name]: numericValue });
  };

  const handleCurrencyFocus = (fieldName) => {
    setEditingField(fieldName);
  };

  const handleCurrencyBlur = () => {
    setEditingField(null);
  };

  useEffect(() => {
    if (editingField) {
      inputRefs.current[editingField].focus();
    }
  }, [editingField]);

  const currencyFields = ['projectedSalePrice', 'realEstateAppraisal', 'revenue', 'ebitda'];

  const renderCurrencyInput = (fieldName, label) => (
    <div key={fieldName}>
      <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        {editingField === fieldName ? (
          <input
            ref={(el) => inputRefs.current[fieldName] = el}
            type="text"
            name={fieldName}
            id={fieldName}
            value={formData[fieldName] || ''}
            onChange={handleCurrencyChange}
            onBlur={handleCurrencyBlur}
            className="block w-full pr-10 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="0.00"
            required
          />
        ) : (
          <div
            onClick={() => handleCurrencyFocus(fieldName)}
            className="block w-full pr-10 sm:text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 bg-white cursor-text"
          >
            {formatCurrency(formData[fieldName]) || '$0.00'}
          </div>
        )}
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">USD</span>
        </div>
      </div>
    </div>
  );

  const businessDocuments = [
    {
      name: 'Business Tax Returns',
      description: 'Upload the last 3 years of business tax returns.',
      required: true,
      labels: [
        'Business Tax Return - Current Year',
        'Business Tax Return - Last Year',
        'Business Tax Return - 2 Years Ago'
      ]
    },
    {
      name: 'CIM',
      description: 'Upload the Confidential Information Memorandum (CIM).',
      required: false,
      labels: ['CIM']
    },
    {
      name: 'Addbacks',
      description: 'Upload the addbacks document.',
      required: false,
      labels: ['Addbacks']
    }
  ];

  const handleFileUpload = async (event, documentName, documentLabel) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Attempting file upload...');
      console.log('Application ID:', formData.id);
      console.log('Broker ID:', formData.brokerId);

      if (!formData.id || !formData.brokerId) {
        console.error('Missing application ID or broker ID');
        alert('Unable to upload file. Please ensure the form has been saved.');
        return;
      }

      try {
        // Check if a file already exists for this document and label
        const existingFile = uploadedFiles.find(f => f.documentName === documentName && f.label === documentLabel);

        // If a file exists, delete it from storage and Firestore
        if (existingFile) {
          const oldStorageRef = ref(storage, existingFile.fileUrl);
          await deleteObject(oldStorageRef);
          await deleteDoc(doc(db, 'prequalificationMetadata', existingFile.id));
          console.log('Existing file deleted');
        }

        // Upload new file
        const storageRef = ref(storage, `prequalification/brokers/${formData.brokerId}/${formData.id}/${documentName}/${documentLabel}/${file.name}`);
        console.log('Storage reference:', storageRef);
        
        await uploadBytes(storageRef, file);
        console.log('File uploaded successfully');
        
        const fileUrl = await getDownloadURL(storageRef);
        console.log('File URL:', fileUrl);

        const fileMetadata = {
          documentName,
          label: documentLabel,
          fileName: file.name,
          fileUrl,
          uploadedAt: new Date().toISOString(),
          applicationId: formData.id,
          brokerId: formData.brokerId,
          businessName: formData.businessName
        };

        console.log('File metadata:', fileMetadata);
        
        // Save metadata to Firestore
        const metadataRef = collection(db, 'prequalificationMetadata');
        const docRef = await addDoc(metadataRef, fileMetadata);

        // Add the document ID to the metadata
        const updatedMetadata = {
          ...fileMetadata,
          documentId: docRef.id
        };

        // Update local state
        setUploadedFiles(prevFiles => {
          const newFiles = prevFiles.filter(f => !(f.documentName === documentName && f.label === documentLabel));
          return [...newFiles, updatedMetadata];
        });

        console.log('File metadata saved successfully');

        // Send file for processing
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User not authenticated');
        }

        const idToken = await user.getIdToken();

        const requestBody = {
          fileUrl: fileUrl,
          metadata: updatedMetadata,
        };

        console.log('Request body to be sent:', JSON.stringify(requestBody, null, 2));

        const response = await fetch('https://sba-30d9855b23ef.herokuapp.com/process-document', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        console.log('Response status:', response.status);
        console.log('Response status text:', response.statusText);

        if (response.ok) {
          console.log(`Document uploaded and processed successfully for ${documentName}: ${documentLabel}`);
          const responseData = await response.json();
          console.log('Response data:', JSON.stringify(responseData, null, 2));
          setProcessingStatus(prevStatus => ({
            ...prevStatus,
            [`${documentName}-${documentLabel}`]: 'completed'
          }));
        } else {
          throw new Error('Failed to process document');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file. Please try again.');
        setProcessingStatus(prevStatus => ({
          ...prevStatus,
          [`${documentName}-${documentLabel}`]: 'error'
        }));
      }
    }
  };

  const getUploadedFileName = (documentName, label) => {
    const file = uploadedFiles.find(file => 
      file.documentName === documentName && file.label === label
    );
    return file ? file.fileName : null;
  };

  const renderBusinessDocuments = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900">Business Documents</h3>
      {businessDocuments.map((document) => (
        <div key={document.name} className="space-y-2">
          <h4 className="text-sm font-medium text-gray-700">
            {document.name} {!document.required && <span className="text-xs text-gray-500">(Optional)</span>}
          </h4>
          <p className="text-sm text-gray-500">{document.description}</p>
          {document.labels.map((label) => (
            <div key={label} className="flex items-center space-x-4">
              <label htmlFor={label} className="block text-sm font-medium text-gray-700">
                {label}
              </label>
              <div className="relative">
                <input
                  id={label}
                  name={label}
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handleFileUpload(e, document.name, label)}
                  className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100"
                />
                {getUploadedFileName(document.name, label) && (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 pr-2 text-sm text-green-600 pointer-events-none bg-white">
                    {getUploadedFileName(document.name, label)}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const checkBusinessTaxReturns = async (applicationId) => {
    const requiredDocuments = [
      'Business Tax Return - Current Year',
      'Business Tax Return - Last Year',
      'Business Tax Return - 2 Years Ago'
    ];

    try {
      const q = query(
        collection(db, 'prequalificationMetadata'),
        where('applicationId', '==', applicationId),
        where('documentName', '==', 'Business Tax Returns')
      );
      const querySnapshot = await getDocs(q);
      const uploadedTaxReturns = querySnapshot.docs.map(doc => doc.data().label);

      return requiredDocuments.every(doc => uploadedTaxReturns.includes(doc));
    } catch (error) {
      console.error('Error checking business tax returns:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    console.log('Submitting form...');
    console.log('Current formData:', formData);

    try {
      const taxReturnsUploaded = await checkBusinessTaxReturns(formData.id);
      console.log('Tax returns uploaded:', taxReturnsUploaded);
      
      const newStatus = taxReturnsUploaded ? 'Complete' : 'In progress';
      console.log('New status:', newStatus);

      const updatedFormData = {
        ...formData,
        lastUpdated: new Date().toISOString(),
        status: newStatus
      };
      console.log('Saving updatedFormData:', updatedFormData);
      
      // Save the data
      await onSave(updatedFormData);

      // Fetch the updated document to verify the saved status
      const db = getFirestore();
      const docRef = doc(db, 'prequalificationApplications', formData.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Saved document data:', docSnap.data());
      } else {
        console.log('No such document!');
      }

      // Update local state
      setFormData(updatedFormData);
      
      console.log('Form submitted successfully');
      console.log('Final status:', updatedFormData.status);
      
      alert(`Form saved successfully. Status: ${updatedFormData.status}`);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error saving form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">Business Name</label>
        <input
          type="text"
          name="businessName"
          id="businessName"
          value={formData.businessName || ''}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div>
        <label htmlFor="businessDescription" className="block text-sm font-medium text-gray-700">Business Description</label>
        <textarea
          name="businessDescription"
          id="businessDescription"
          value={formData.businessDescription || ''}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          required
          placeholder="Description of your business"
        />
      </div>
      <div>
        <label htmlFor="businessAge" className="block text-sm font-medium text-gray-700">Business Age</label>
        <input
          type="number"
          name="businessAge"
          id="businessAge"
          value={formData.businessAge || ''}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      <StateSelect
        name="businessState"
        label="Business State"
        value={formData.businessState || ''}
        onChange={handleChange}
      />

      {currencyFields.map(field => renderCurrencyInput(field, field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')))}

      {renderBusinessDocuments()}

      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="mr-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
          disabled={isSubmitting}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PrequalifyBusiness;