import React, { useState, useRef, useEffect } from 'react';
import { DollarSign, Percent, HelpCircle } from 'lucide-react';

const LoanStructureForm = ({ formData, handleChange }) => {
  const [inputType, setInputType] = useState('dollar');
  const [localFormData, setLocalFormData] = useState(formData.loanStructure || {});
  const inputRefs = useRef({});
  const totalAmount = parseFloat(formData.loanSize) || 0;

  useEffect(() => {
    setLocalFormData(formData.loanStructure || {});
  }, [formData.loanStructure]);

  const formatDollarAmount = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value.replace(/,/g, '');

    let newValue;
    if (inputType === 'percentage') {
      newValue = value;
    } else {
      const dollars = parseFloat(value) || 0;
      newValue = totalAmount > 0 ? ((dollars / totalAmount) * 100).toFixed(2) : '0';
    }

    setLocalFormData(prevData => {
      const newData = { ...prevData };
      if (field.includes('.')) {
        const [main, sub] = field.split('.');
        newData[main] = { ...newData[main], [sub]: newValue };
      } else {
        newData[field] = newValue;
      }
      return newData;
    });
  };

  const handleInputBlur = () => {
    handleChange({
      target: {
        name: 'loanStructure',
        value: localFormData
      }
    });
  };

  const getFieldValue = (field) => {
    if (field.includes('.')) {
      const [main, sub] = field.split('.');
      return localFormData[main]?.[sub] || '';
    }
    return localFormData[field] || '';
  };

  const renderInput = (label, field) => {
    const value = getFieldValue(field);
    const displayValue = inputType === 'dollar'
      ? formatDollarAmount(Math.round((parseFloat(value) || 0) / 100 * totalAmount))
      : value;

    return (
      <div key={field} className="mb-4">
        <label htmlFor={field} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          {inputType === 'dollar' && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
          )}
          <input
            ref={el => inputRefs.current[field] = el}
            type="text"
            id={field}
            value={displayValue}
            onChange={(e) => handleInputChange(e, field)}
            onBlur={handleInputBlur}
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 ${
              inputType === 'dollar' ? 'pl-7' : ''
            }`}
            placeholder={`Enter ${inputType === 'percentage' ? '%' : 'amount'}`}
          />
          {inputType === 'percentage' && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">%</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const totalPercentage = () => {
    return Object.entries(localFormData).reduce((sum, [key, value]) => {
      if (key === 'equityInjection') {
        return sum + (parseFloat(value.personal) || 0) + (parseFloat(value.fullStandby) || 0);
      }
      return sum + (parseFloat(value) || 0);
    }, 0);
  };

  const isCloseToHundred = (value) => {
    return Math.abs(value - 100) < 0.01;
  };

  const handleInputTypeChange = (newType) => (e) => {
    e.preventDefault();
    setInputType(newType);
  };

  return (
    <div className="col-span-2 mt-8">
      <div className="bg-white p-6 rounded-md shadow">
        <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
          Expected Loan Structure
          <HelpCircle className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" title="Breakdown of the loan structure" />
        </h3>
        
        <div className="flex justify-start mb-6">
          <div className="inline-flex rounded-md bg-gray-100 p-0.5">
            <button
              onClick={handleInputTypeChange('percentage')}
              className={`flex items-center space-x-1 px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 ${
                inputType === 'percentage' ? 'bg-white shadow text-blue-600' : 'text-gray-600 hover:bg-gray-200'
              }`}
            >
              <Percent size={16} />
              <span>Percentage</span>
            </button>
            <button
              onClick={handleInputTypeChange('dollar')}
              className={`flex items-center space-x-1 px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 ${
                inputType === 'dollar' ? 'bg-white shadow text-blue-600' : 'text-gray-600 hover:bg-gray-200'
              }`}
            >
              <DollarSign size={16} />
              <span>Dollar</span>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h4 className="text-lg font-medium text-gray-700 mb-4">Equity Injection</h4>
            {renderInput('Personal', 'equityInjection.personal')}
            {renderInput('Full Standby', 'equityInjection.fullStandby')}
          </div>
          <div>
            {renderInput('Conventional Loan', 'conventionalLoan')}
            {renderInput('SBA 7a Loan', 'sba7aLoan')}
            {renderInput("Seller's Note", 'sellersNote')}
          </div>
        </div>

        <div className="flex items-center justify-between pt-6 border-t border-gray-200 mt-8">
          <span className="text-lg font-medium text-gray-700">Total</span>
          <span className="text-2xl font-bold text-blue-600">
            {inputType === 'percentage'
              ? `${totalPercentage().toFixed(2)}%`
              : `$${formatDollarAmount(Math.round(totalPercentage() / 100 * totalAmount))}`}
          </span>
        </div>

        {!isCloseToHundred(totalPercentage()) && (
          <p className="text-red-500 mt-4 text-sm">The total must add up to 100% or the full loan amount.</p>
        )}
        {(parseFloat(localFormData.equityInjection?.personal) || 0) + (parseFloat(localFormData.equityInjection?.fullStandby) || 0) < 10 && (
          <p className="text-red-500 mt-2 text-sm">The combined Equity Injection must be at least 10%.</p>
        )}
      </div>
    </div>
  );
};

export default LoanStructureForm;