import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DollarSign, Calendar, Clock, MapPin, TrendingUp, Edit3 } from 'lucide-react';
import logo from '../images/images.png';

const LightModeLoanCard = ({ 
  id,
  name, 
  loanSize, 
  projectedSalePrice,
  businessState, 
  businessAge,
  submittedAt,
  ebitda,
  lastUserUpdate,
  onClick,
  isPrequalification,
  loanPurpose
}) => {
  const navigate = useNavigate();
  const isWorkingCapital = loanPurpose === 'workingCapital';

  const handleCardClick = () => {
    navigate(`/loan-details/${id}`);
  };

  return (
    <div 
      className={`w-96 rounded-xl overflow-hidden shadow-lg border border-gray-200 cursor-pointer ${isWorkingCapital ? 'bg-blue-50' : 'bg-white'}`}
      onClick={onClick}
    >
      <div className="p-6 border-b border-gray-100">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-800">{name}</h3>
          <img src={logo} alt="Company Logo" className="w-5 h-5" />
        </div>
        <div className="flex items-center text-gray-600 text-sm">
          <MapPin className="w-4 h-4 mr-2 text-blue-500" />
          <p>{businessState}</p>
        </div>
      </div>
      <div className="p-6 space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-600">
            {isPrequalification ? "Projected Sale Price" : "Loan Amount"}
          </span>
          <div className="flex items-center">
            <DollarSign className="w-5 h-5 text-green-600 mr-1" />
            <span className="text-2xl font-bold text-gray-800">
              ${Number(isPrequalification ? projectedSalePrice : loanSize).toLocaleString()}
            </span>
          </div>
        </div>
        <div className={`rounded-lg p-4 space-y-2 ${isWorkingCapital ? 'bg-blue-100' : 'bg-blue-50'}`}>
          <div className="flex items-center justify-between">
            <span className="text-blue-700 font-medium">Business Age</span>
            <div className="flex items-center text-gray-800">
              <Calendar className="w-4 h-4 mr-2 text-blue-500" />
              {businessAge}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-blue-700 font-medium">EBITDA</span>
            <div className="flex items-center text-gray-800">
              <TrendingUp className="w-4 h-4 mr-2 text-blue-500" />
              ${Number(ebitda).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
      <div className={`px-6 py-4 flex items-center justify-between ${isWorkingCapital ? 'bg-blue-100' : 'bg-gray-50'}`}>
        <span className="text-gray-600 text-sm">Submitted</span>
        <div className="flex items-center text-gray-700 text-sm">
          <Clock className="w-4 h-4 mr-2 text-blue-500" />
          {submittedAt}
        </div>
      </div>
      <div className={`px-6 py-4 flex items-center justify-between ${isWorkingCapital ? 'bg-blue-200' : 'bg-gray-100'}`}>
        <span className="text-gray-600 text-sm">Last User Update</span>
        <div className="flex items-center text-gray-700 text-sm">
          <Edit3 className="w-4 h-4 mr-2 text-red-500" />
          {lastUserUpdate ? lastUserUpdate : 'N/A'}
        </div>
      </div>
    </div>
  );
};

export default LightModeLoanCard;