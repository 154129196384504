import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, where, orderBy, limit, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import LightModeLoanCard from './LightModeLoanCard';
import { BarsArrowUpIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { BankManagement } from './BankManagement';
import { PreLenderCard, InTalksCard, ClosedDealCard, PrequalificationCard } from './LoanCards';


const tabs = [
  { name: 'Recent Activity', section: 'recentActivity' },
  { name: 'Pre-Lender', section: 'preLender' },
  { name: 'In Talks', section: 'inTalks' },
  { name: 'Closed Deals', section: 'closedDeals' },
  { name: 'Prequalifications', section: 'prequalifications' },
  { name: 'Bank Management', section: 'bankManagement' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [recentUsers, setRecentUsers] = useState([]);
  const [preLenderLoans, setPreLenderLoans] = useState([]);
  const [inTalksLoans, setInTalksLoans] = useState([]);
  const [closedDeals, setClosedDeals] = useState([]);
  const [prequalifications, setPrequalifications] = useState([]);
  const [activeSection, setActiveSection] = useState('recentActivity');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchRecentUsers = async () => {
      try {
        const usersRef = collection(db, 'loanApplications');
        const q = query(usersRef, orderBy('createdAt', 'desc'), limit(5));
        const querySnapshot = await getDocs(q);
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRecentUsers(usersData);
      } catch (error) {
        console.error('Error fetching recent users:', error);
      }
    };

    const fetchPreLenderLoans = async () => {
      try {
        const loansRef = collection(db, 'loanApplications');
        const q = query(loansRef, where('status', '==', 'Pre-lender'));
        const querySnapshot = await getDocs(q);
        const loansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPreLenderLoans(loansData);
      } catch (error) {
        console.error('Error fetching pre-lender loans:', error);
      }
    };

    const fetchInTalksLoans = async () => {
      try {
        const loansRef = collection(db, 'loanApplications');
        const q = query(loansRef, where('status', '==', 'in-talks'));
        const querySnapshot = await getDocs(q);
        const loansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInTalksLoans(loansData);
      } catch (error) {
        console.error('Error fetching in-talks loans:', error);
      }
    };

    const fetchClosedDeals = async () => {
      try {
        const loansRef = collection(db, 'loanApplications');
        const q = query(loansRef, where('status', '==', 'closed'));
        const querySnapshot = await getDocs(q);
        const loansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClosedDeals(loansData);
      } catch (error) {
        console.error('Error fetching closed deals:', error);
      }
    };

    const fetchPrequalifications = async () => {
      console.log('Fetching prequalifications...');
      try {
        const prequalRef = collection(db, 'prequalificationApplications');
        const querySnapshot = await getDocs(prequalRef);
        console.log('Prequalifications query snapshot:', querySnapshot);
        const prequalData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Parsed prequalification data:', prequalData);
        setPrequalifications(prequalData);
      } catch (error) {
        console.error('Error fetching prequalifications:', error);
      }
    };

    fetchRecentUsers();
    fetchPreLenderLoans();
    fetchInTalksLoans();
    fetchClosedDeals();
    fetchPrequalifications();
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };

  const handleCardClick = (loan) => {
    console.log('Card clicked:', loan);
    if (activeSection === 'prequalifications') {
      console.log('Navigating to prequalification details:', `/prequalification-details/${loan.id}`);
      navigate(`/prequalification-details/${loan.id}`);
    } else {
      navigate(`/loan-details/${loan.id}`);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  const renderSection = () => {
    console.log('Rendering section:', activeSection);
    let loansToRender;
    let sectionTitle;

    if (searchTerm !== '') {
      const allLoans = [...preLenderLoans, ...inTalksLoans, ...closedDeals, ...prequalifications];
      loansToRender = allLoans.filter(loan => 
        (loan.businessName && loan.businessName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (loan.businessState && loan.businessState.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (loan.businessSummary && loan.businessSummary.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (loan.loanPurpose && loan.loanPurpose.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      sectionTitle = "Search Results";
    } else {
      switch (activeSection) {
        case 'recentActivity':
          loansToRender = recentUsers;
          sectionTitle = "Recent Activity";
          break;
        case 'preLender':
          loansToRender = preLenderLoans;
          sectionTitle = "Pre-Lender Loans";
          break;
        case 'inTalks':
          loansToRender = inTalksLoans;
          sectionTitle = "In Talks with Lender";
          break;
        case 'closedDeals':
          loansToRender = closedDeals;
          sectionTitle = "Closed Deals";
          break;
        case 'prequalifications':
          loansToRender = prequalifications;
          sectionTitle = "Prequalifications";
          break;
        case 'bankManagement':
          return <BankManagement />;
        default:
          loansToRender = [];
          sectionTitle = "";
      }
    }

    console.log('Loans to render:', loansToRender);

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-semibold mb-4">{sectionTitle}</h2>
        {loansToRender.length === 0 ? (
          <p>No loans found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {loansToRender.map((loan) => {
              console.log('Rendering loan card:', loan);
              const displayName = loan.businessName || `${loan.firstName} ${loan.lastName}`.trim();
              return (
                <LightModeLoanCard
                  key={loan.id}
                  id={loan.id}
                  name={displayName}
                  loanSize={loan.projectedSalePrice || loan.loanSize}
                  loanPurpose={loan.businessSummary || loan.loanPurpose}
                  businessState={loan.businessState}
                  businessAge={loan.businessAge}
                  submittedAt={loan.lastUpdated ? new Date(loan.lastUpdated).toLocaleDateString() : (loan.createdAt && loan.createdAt.toDate ? loan.createdAt.toDate().toLocaleDateString() : 'N/A')}
                  ebitda={loan.ebitda}
                  onClick={() => handleCardClick(loan)}
                  isBlue={activeSection === 'prequalifications'}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const handleSaveFinancials = async (loanId, year, financials) => {
    const loanRef = doc(db, 'loanApplications', loanId);
    
    try {
      await updateDoc(loanRef, {
        [`financialAnalysis.${year}`]: financials
      });
      console.log('Financials saved successfully');
    } catch (error) {
      console.error('Error saving financials:', error);
      throw error;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Admin Dashboard</h3>
            <div className="mt-3 sm:mt-0 sm:ml-4">
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setActiveSection(tab.section)}
                      className={classNames(
                        activeSection === tab.section
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
              <div className="sm:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={activeSection}
                  onChange={(e) => setActiveSection(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.section}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="mt-3 sm:mt-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                {/* Search input */}
                <div className="mt-2 flex rounded-md shadow-sm">
                  <div className="relative flex-grow focus-within:z-10">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Search applicants"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                  <button
                    type="button"
                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <BarsArrowUpIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Sort
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  onClick={handleLogout}
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-offset"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6">
          <div className="sm:px-0">
            {renderSection()}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;

