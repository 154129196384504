import { Fragment, useState, useEffect, useRef } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import DocumentUploader, { addOwnerButtonRef } from './documentTracker';
import FormEditor from './fullApplication';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import ChatBot from './chat';
import logo from '../images/images.png';
import 'intro.js/introjs.css';
import introJs from 'intro.js';
import TermSheetViewer from './TermSheetViewer';
import ApplicationView from './ApplicationView';
import DealRoom from './DealRoom';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

const navigation = [
  { id: 'currentTask', name: 'Document Upload' },
  { id: 'applicationView', name: 'Application View' },
  { id: 'termSheets', name: 'Term Sheets' },
];

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const [selectedItem, setSelectedItem] = useState('currentTask');
  const [currentUser, setCurrentUser] = useState(null);
  const [isChatbotExpanded, setIsChatbotExpanded] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [tutorialStarted, setTutorialStarted] = useState(false);
  const [currentLoanId, setCurrentLoanId] = useState(null);
  const addOwnerButtonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loanType, setLoanType] = useState(null);

  const startTutorial = () => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          element: '#documentUploader',
          intro: 'This is where you can upload the required documents for your loan application.',
        },
        {
          element: addOwnerButtonRef.current,
          intro: 'Click here to add additional owners to your loan application.',
        },
        {
          element: '#startApplicationButton',
          intro: 'Once you have uploaded all the necessary documents, click here to start your loan application.',
        },
      ],
    });
    intro.start();
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const db = getFirestore();
          const formQuery = query(collection(db, 'loanApplications'), where('userId', '==', currentUser.uid));
          const querySnapshot = await getDocs(formQuery);
          if (!querySnapshot.empty) {
            const formDoc = querySnapshot.docs[0];
            const formData = formDoc.data();
            const capitalizedFirstName = formData.firstName.charAt(0).toUpperCase() + formData.firstName.slice(1);
            setFirstName(capitalizedFirstName);
            setCurrentLoanId(formDoc.id);
            setLoanType(formData.loanPurpose); // Set the loan type
          } else {
            setFirstName('');
            setCurrentLoanId(null);
            setLoanType(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    if (addOwnerButtonRef.current) {
      if (!tutorialStarted) {
        startTutorial();
        setTutorialStarted(true);
      }
    }
  }, [addOwnerButtonRef, tutorialStarted]);

  const handleFormDataChange = (formData) => {
    const capitalizedFirstName = formData.firstName.charAt(0).toUpperCase() + formData.firstName.slice(1);
    setFirstName(capitalizedFirstName);
  };
  
  const handleNavigationClick = (itemId) => {
    setSelectedItem(itemId);
  };

  const toggleChatbotExpanded = () => {
    setIsChatbotExpanded(!isChatbotExpanded);
  };

  const handleReviewSubmit = () => {
    setSelectedItem('applicationView');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Filter navigation items based on loan type
  const filteredNavigation = navigation.filter(item => 
    !['workingCapital-nonsba', 'workingCapital'].includes(loanType) || item.id !== 'applicationView'
  );

  return (
    <div className="min-h-full">
      <Popover as="header" className="bg-blue-900 pb-24">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="relative flex items-center justify-center py-5 lg:justify-between">
                <div className="absolute left-0 flex-shrink-0 lg:static">
                  <a href="#">
                    <span className="sr-only">Your Company</span>
                    <img
                      className="h-8 w-auto"
                      src={logo}
                      alt="Your Company"
                    />
                  </a>
                </div>

                <div className="absolute right-0 top-5 flex-shrink-0 lg:hidden">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-blue-800 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-900 focus:ring-white">
                        {navigation.find(item => item.id === selectedItem)?.name}
                        <ChevronDownIcon className="-mr-1 h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {navigation.map((item) => (
                            <Menu.Item key={item.id}>
                              {({ active }) => (
                                <button
                                  onClick={() => handleNavigationClick(item.id)}
                                  className={classNames(
                                    active ? 'bg-blue-100 text-blue-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm w-full text-left'
                                  )}
                                >
                                  {item.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="hidden border-t border-white border-opacity-20 py-5 lg:block">
                <div className="grid grid-cols-3 items-center gap-8">
                  <div className="col-span-2">
                    <nav className="flex space-x-4">
                      {filteredNavigation.map((item) => (
                        <button
                          key={item.id}
                          onClick={() => handleNavigationClick(item.id)}
                          className={classNames(
                            selectedItem === item.id ? 'text-white' : 'text-indigo-100',
                            'rounded-md bg-white bg-opacity-0 px-3 py-2 text-sm font-medium hover:bg-opacity-10'
                          )}
                          aria-current={selectedItem === item.id ? 'page' : undefined}
                        >
                          {item.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popover>
      <main className="-mt-24 pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Page title</h1>
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            <div className={`grid grid-cols-1 gap-4 ${selectedItem === 'termSheets' || selectedItem === 'applicationView' || selectedItem === 'dealRoom' ? 'lg:col-span-3' : isChatbotExpanded ? 'lg:col-span-1' : 'lg:col-span-2'}`}>
              <section aria-labelledby="section-1-title">
                <h2 className="sr-only" id="section-1-title">
                  Section title
                </h2>
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className={selectedItem === 'applicationView' ? '' : 'p-6'}>
                    {selectedItem === 'currentTask' ? (
                      <div id="documentUploader">
                        <DocumentUploader 
                          addOwnerButtonRef={addOwnerButtonRef} 
                          onReviewSubmit={handleReviewSubmit}
                        />
                      </div>
                    ) : selectedItem === 'termSheets' ? (
                      <div>
                        {currentLoanId ? (
                          <TermSheetViewer loanId={currentLoanId} />
                        ) : (
                          <p className="mt-1 text-sm text-gray-500">No active loan application found.</p>
                        )}
                      </div>
                    ) : selectedItem === 'applicationView' && !['workingCapital-nonsba', 'workingCapital'].includes(loanType) ? (
                      <div>
                        {currentLoanId ? (
                          <ApplicationView loanId={currentLoanId} />
                        ) : (
                          <p className="mt-1 text-sm text-gray-500">No active loan application found.</p>
                        )}
                      </div>
                    ) : selectedItem === 'dealRoom' ? (
                      <DealRoom />
                    ) : null}
                  </div>
                </div>
              </section>
            </div>
            {selectedItem !== 'termSheets' && selectedItem !== 'applicationView' && selectedItem !== 'dealRoom' && (
              <div className={`grid grid-cols-1 gap-4 ${isChatbotExpanded ? 'lg:col-span-2' : 'lg:col-span-1'}`}>
                <section aria-labelledby="section-2-title">
                  <h2 className="sr-only" id="section-2-title">
                    Section title
                  </h2>
                  <div className="overflow-hidden rounded-lg bg-white shadow">
                    <ChatBot
                      firstName={firstName}
                      toggleChatbotExpanded={toggleChatbotExpanded}
                      isChatbotExpanded={isChatbotExpanded}
                    />
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}