import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, addDoc, arrayUnion } from 'firebase/firestore';
import { db, auth, storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ArrowLeftIcon, ArrowDownTrayIcon, ArrowUpTrayIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import FinancialMetricsDisplay from './FinancialMetricsDisplay';
import { UserIcon, BuildingOfficeIcon, MapPinIcon, CurrencyDollarIcon, ClockIcon } from '@heroicons/react/24/outline';

// Add this function at the top of your file, outside of the component
const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const BankPrequalificationDetails = () => {
  const { prequalId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [prequal, setPrequal] = useState(null);
  const [ocrData, setOcrData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const bankId = location.state?.bankId;
  const [assumptions, setAssumptions] = useState({
    term: 10, // years
    rate: 10.5, // percentage
  });

  useEffect(() => {
    const fetchPrequalDetails = async () => {
      if (!prequalId || !bankId) {
        console.error('Prequalification ID or Bank ID is missing');
        navigate('/bank-portal');
        return;
      }

      try {
        const prequalDoc = await getDoc(doc(db, 'prequalificationApplications', prequalId));
        if (prequalDoc.exists()) {
          const prequalData = { id: prequalDoc.id, ...prequalDoc.data() };
          setPrequal(prequalData);

          // Fetch OCR metadata
          const ocrMetadataRef = collection(db, 'OCRMetadata');
          const q = query(ocrMetadataRef, where('applicationId', '==', prequalId));
          const querySnapshot = await getDocs(q);
          
          const processedData = {};
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.documentName === "Business Tax Returns") {
              const year = data.label.match(/\d{4}/) ? data.label.match(/\d{4}/)[0] : data.label;
              processedData[year] = {
                ...data.ocrResult,
                other_deductions: data.ocrResult.other_deductions.reduce((acc, item) => {
                  acc[item.category] = item.amount;
                  return acc;
                }, {}),
                year: year,
              };
            }
          });

          console.log('Fetched OCR Data:', processedData);
          setOcrData(processedData);
        } else {
          console.error('Prequalification not found');
          navigate('/bank-portal');
        }
      } catch (error) {
        console.error('Error fetching prequalification details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrequalDetails();
  }, [prequalId, bankId, navigate]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        console.log('No user is signed in');
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleDownloadAllFiles = () => {
    if (prequal && prequal.dropboxLink) {
      window.open(prequal.dropboxLink, '_blank');
    } else {
      alert('No Dropbox link available for this prequalification.');
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleUploadTermSheet = async () => {
    if (!file || !bankId || !prequalId) {
      console.error('Missing required information');
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is currently signed in');
      }

      console.log('Current user:', user.uid);
      const idTokenResult = await user.getIdTokenResult(true);
      console.log('User claims:', idTokenResult.claims);
      console.log('Bank ID:', bankId);
      console.log('Prequal ID:', prequalId);

      // Upload file to Storage
      const storageRef = ref(storage, `prequalifiedTermSheets/${bankId}/${prequalId}/${file.name}`);
      console.log('Attempting to upload to:', storageRef.fullPath);
      await uploadBytes(storageRef, file);
      console.log('File uploaded successfully');

      const downloadURL = await getDownloadURL(storageRef);
      console.log('Download URL:', downloadURL);

      // Add document to Firestore
      console.log('Adding document to Firestore...');
      const termSheetRef = await addDoc(collection(db, 'prequalifiedTermSheets'), {
        bankId: bankId,
        prequalificationId: prequalId,
        fileName: file.name,
        fileUrl: downloadURL,
        uploadedAt: new Date(),
        status: 'pending',
        uploadedBy: user.uid,
      });
      console.log('Term sheet document added to Firestore with ID:', termSheetRef.id);

      // Update prequalificationApplication document
      console.log('Updating prequalification application...');
      await updateDoc(doc(db, 'prequalificationApplications', prequalId), {
        termSheets: arrayUnion(termSheetRef.id)
      });
      console.log('Prequalification application updated with term sheet reference');

      // Fetch prequalification application details
      console.log('Fetching prequalification application details...');
      const prequalRef = doc(db, 'prequalificationApplications', prequalId);
      const prequalSnap = await getDoc(prequalRef);
      if (prequalSnap.exists()) {
        const prequalData = prequalSnap.data();
        console.log('Prequalification data:', JSON.stringify(prequalData, null, 2));
        try {
          await createEmailDocument(prequalData, downloadURL);
          console.log('Email document created successfully');
        } catch (emailError) {
          console.error('Error creating email document:', emailError);
          // Continue with the process even if email creation fails
        }
      } else {
        console.error('Prequalification application not found');
      }

      setIsModalOpen(false);
      setFile(null);
      alert('Term sheet uploaded successfully');
    } catch (error) {
      console.error('Error in term sheet upload process:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      alert(`Error uploading term sheet: ${error.message}`);
    }
  };

  const createEmailDocument = async (prequalData, termSheetUrl) => {
    try {
      console.log('Creating email document for prequalification applicant');
      console.log('Prequal data:', JSON.stringify(prequalData, null, 2));
      
      const emailRef = collection(db, 'email');

      const loginUrl = "https://lended.ai/login"; // Replace with your actual login URL

      if (!prequalData.email) {
        console.error('No email address found in prequalification data');
        throw new Error('Missing email address for applicant');
      }

      const emailData = {
        to: prequalData.email,
        from: "max@sbacapital.ai",
        replyTo: "max@sbacapital.ai",
        subject: "You've received a term sheet for your prequalification application!",
        templateName: "PrequalTermSheetNotification",
        message: {
          subject: "You've received a term sheet for your prequalification application!",
          firstName: prequalData.firstName || 'Applicant',
          prequalDetails: "A bank has uploaded a term sheet for your prequalification application. Please log in to review the details.",
          html: `<p>Hello ${prequalData.firstName || 'Applicant'},</p>

<p>Great news! A bank has reviewed your prequalification application and uploaded a term sheet for your consideration. This is an important step in the loan process.</p>

<p>Please <a href="${loginUrl}">log in to your account</a> to review the term sheet and the details of the offer.</p>

<p>If you have any questions about the term sheet or need assistance understanding the terms, don't hesitate to reach out to us.</p>

<p>We're excited about this progress in your prequalification application!</p>

<p>Best regards,</p>
<p>Max Krakow</p>
<p>Lended</p>
`,
        },
      };

      console.log('Email data prepared:', JSON.stringify(emailData, null, 2));

      const emailDoc = await addDoc(emailRef, emailData);
      console.log('Email document created with ID:', emailDoc.id);
      return emailDoc.id;
    } catch (error) {
      console.error('Error creating email document:', error);
      throw error;
    }
  };

  const handleRejectPrequal = async () => {
    if (!prequalId || !bankId) {
      console.error('Prequalification ID or Bank ID is missing');
      return;
    }

    try {
      // Update the bank assignment status
      const assignmentsRef = collection(db, 'bankAssignments');
      const assignmentQuery = query(assignmentsRef, 
        where('bankId', '==', bankId),
        where('prequalificationId', '==', prequalId)
      );
      const assignmentSnapshot = await getDocs(assignmentQuery);

      if (!assignmentSnapshot.empty) {
        const assignmentDoc = assignmentSnapshot.docs[0];
        await updateDoc(doc(db, 'bankAssignments', assignmentDoc.id), {
          currentStage: {
            name: 'bankRejected',
            timestamp: new Date()
          }
        });
        console.log('Prequalification rejected successfully');
        navigate('/bank-portal'); // Redirect to bank portal
      } else {
        console.error('No matching bank assignment found');
        alert('No bank assignment found for this prequalification.');
      }
    } catch (error) {
      console.error('Error rejecting prequalification:', error);
      alert('An error occurred while rejecting the prequalification.');
    }
  };

  const calculateAnnualDebtService = (loanAmount) => {
    const monthlyRate = assumptions.rate / 12 / 100;
    const numberOfPayments = assumptions.term * 12;
    const monthlyPayment = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    return monthlyPayment * 12;
  };

  const renderAssumptions = () => {
    return (
      <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">DSCR Calculation Assumptions</h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Loan Term</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assumptions.term} years</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Interest Rate</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assumptions.rate}%</dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  const calculateLoanStructure = () => {
    if (!prequal || !prequal.loanStructure) return {};

    const storedLoanStructure = prequal.loanStructure;
    const projectedSalePrice = parseFloat(storedLoanStructure.projectedSalePrice) || 0;
    const loanPercentage = parseFloat(storedLoanStructure.loanPercentage) || 0;
    const workingCapital = parseFloat(storedLoanStructure.workingCapital) || 0;
    const guaranteeFee = parseFloat(storedLoanStructure.guaranteeFee) || 0;
    const closingCost = parseFloat(storedLoanStructure.closingCost) || 0;
    const packagingCost = parseFloat(storedLoanStructure.packagingCost) || 0;

    const totalProjectCost = projectedSalePrice + workingCapital + guaranteeFee + closingCost + packagingCost;
    const calculatedLoanAmount = totalProjectCost * (loanPercentage / 100);

    const annualDebtService = calculateAnnualDebtService(calculatedLoanAmount);

    return {
      projectedSalePrice,
      loanPercentage,
      workingCapital,
      guaranteeFee,
      closingCost,
      packagingCost,
      totalProjectCost,
      loanAmount: calculatedLoanAmount,
      annualDebtService,
    };
  };

  const formatFinancialData = (financialAnalysis) => {
    const formattedData = {};
    for (const [key, value] of Object.entries(financialAnalysis)) {
      const year = key.match(/\d{4}/) ? key.match(/\d{4}/)[0] : key;
      formattedData[year] = {
        ...value,
        grossRevenue: Number(value.grossRevenue || 0),
        costOfGoodsSold: Number(value.costOfGoodsSold || 0),
        ordinaryBusinessIncome: Number(value.ordinaryBusinessIncome || 0),
        officerCompensation: Number(value.officerCompensation || 0),
        depreciation: Number(value.depreciation || 0),
        interest: Number(value.interest || 0),
        addBacks: value.addBacks || {},
      };
    }
    return formattedData;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!prequal) {
    return <div>No prequalification data found.</div>;
  }

  const formatCurrency = (value) => {
    return value ? `$${parseFloat(value).toLocaleString()}` : 'N/A';
  };

  const applicantInfo = [
    { label: 'Business name', value: prequal.businessName || 'N/A', icon: BuildingOfficeIcon },
    { label: 'Business age', value: prequal.businessAge ? `${prequal.businessAge} years` : 'N/A', icon: ClockIcon },
    { label: 'Business state', value: prequal.businessState || 'N/A', icon: MapPinIcon },
    { label: 'Listed Sale Price', value: formatCurrency(prequal.projectedSalePrice), icon: CurrencyDollarIcon },
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <button
                        onClick={() => navigate(-1)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <ArrowLeftIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">Back</span>
                      </button>
                    </div>
                  </li>
                  <li className="flex items-center">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      {`${prequal.businessName || 'Business'} Prequalification Details`}
                    </h2>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <button
                onClick={handleDownloadAllFiles}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
              >
                <ArrowDownTrayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Download All Files
              </button>
              <button
                onClick={() => setIsModalOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mr-2"
              >
                <ArrowUpTrayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Upload Term Sheet
              </button>
              <button
                onClick={handleRejectPrequal}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Reject Prequalification
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          {/* Add this new section for the upcoming features */}
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-8" role="alert">
            <p className="font-bold">Feedback</p>
            <p> Please let us know what else we can add to make your review process easier - jake@sbacapital.ai </p>
          </div>

          {/* Elegant Applicant Information Card */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-4 py-5 sm:px-6 bg-gradient-to-r from-gray-50 to-gray-100 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Applicant Information</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal and business details</p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 lg:grid-cols-4">
                {applicantInfo.map((item) => (
                  <div key={item.label} className="relative">
                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                      <item.icon className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {item.label}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 font-semibold">{item.value}</dd>
                  </div>
                ))}
              </dl>
              <div className="mt-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                  <UserIcon className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Business description
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{prequal.businessDescription || 'N/A'}</dd>
              </div>
            </div>
          </div>

          {/* Detailed Financial Analysis */}
          <div className="mb-8">
            {Object.keys(ocrData).length > 0 ? (
              <FinancialMetricsDisplay 
                ocrData={ocrData} 
                loanData={prequal}
                isPrequalification={true}
              />
            ) : (
              <p>No financial analysis or loan structure data available.</p>
            )}
          </div>

          {/* DSCR Calculation Assumptions */}
          {renderAssumptions()}

          {/* Grey Spacer */}
          <div className="my-8 h-px bg-gray-200"></div>

          {/* Dropbox Link Section */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">File Access</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Link to all uploaded files.</p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Dropbox Link</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {prequal.dropboxLink ? (
                      <a 
                        href={prequal.dropboxLink} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800"
                      >
                        View Files
                      </a>
                    ) : (
                      'No link available'
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </main>

      {/* Add this modal code at the end of the return statement */}
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setIsModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Upload Term Sheet
                      </Dialog.Title>
                      <div className="mt-2">
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="mt-1 block w-full text-sm text-slate-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-violet-50 file:text-violet-700
                            hover:file:bg-violet-100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={handleUploadTermSheet}
                    >
                      Upload
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default BankPrequalificationDetails;