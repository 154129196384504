import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Button } from './button';
import { Logo } from './logo';
import logo from '../images/images.png';

function NavLink({ to, children }) {
  return (
    <Link
      to={to}
      className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 whitespace-nowrap"
    >
      {children}
    </Link>
  );
}

export function Header() {
  const [showBanner, setShowBanner] = useState(true);

  const dismissBanner = () => {
    setShowBanner(false);
  };

  return (
    <>
      {/* Banner */}
      {showBanner && (
        <div className="flex items-center gap-x-6 bg-blue-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <p className="text-sm leading-6 text-white">
            <a href="https://calendly.com/lended" target="_blank" rel="noopener noreferrer">
              <strong className="font-semibold">Want some guidance?</strong>
              <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                <circle r={1} cx={1} cy={1} />
              </svg>
              Book a free SBA consultation here&nbsp;<span aria-hidden="true">&rarr;</span>
            </a>
          </p>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              onClick={dismissBanner}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
            </button>
          </div>
        </div>
      )}

      {/* Main header content */}
      <header className="py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <nav className="relative z-50 flex justify-between">
            <div className="flex items-center md:gap-x-16">
              <Link to="/" aria-label="Home">
                <img className="h-12 w-auto" src={logo} alt="Logo" />
              </Link>
              <div className="hidden md:flex md:gap-x-6">
                <NavLink to="/">Home</NavLink>
                <NavLink to="/loan-calculator">Loan Calculator</NavLink>
                <NavLink to="/blog">Blog</NavLink> {/* Add this line */}
              </div>
            </div>
            <div className="flex items-center gap-x-3 md:gap-x-6">
              <div>
                <NavLink to="/login">
                  <span className="whitespace-nowrap text-sm md:text-base">Sign in</span>
                </NavLink>
              </div>
              <Button href="https://calendly.com/lended" color="mainBlue">
                <span className="whitespace-nowrap">
                  Free Consultation
                </span>
              </Button>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
