import React, { useState } from 'react';
import { getAuth, getMultiFactorResolver, TotpMultiFactorGenerator, PhoneMultiFactorGenerator, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase'; // Make sure to import your Firestore instance
import { collection, query, where, getDocs } from 'firebase/firestore';
import logo from './images/images.png';

export default function LoginArea() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [mfaRequired, setMfaRequired] = useState(false);
  const [otp, setOtp] = useState('');
  const [selectedFactorIndex, setSelectedFactorIndex] = useState(0);
  const [resolver, setResolver] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const [showSignUpNotification, setShowSignUpNotification] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setShowSignUpNotification(false);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user) {
        console.log('User logged in:', userCredential.user.uid);
        handleRedirect(userCredential.user);
      } else {
        throw new Error('No user returned from signInWithEmailAndPassword');
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.code === 'auth/multi-factor-auth-required') {
        const multiFactorResolver = getMultiFactorResolver(auth, error);
        setResolver(multiFactorResolver);
        setMfaRequired(true);
      } else if (error.code === 'auth/user-not-found') {
        // Email doesn't exist, show sign up notification
        setShowSignUpNotification(true);
      } else {
        setError('Invalid email or password. Please try again.');
      }
    }
  };

  const handleMfaSubmit = async (e) => {
    e.preventDefault();
    try {
      const selectedHint = resolver.hints[selectedFactorIndex];
      let multiFactorAssertion;

      if (selectedHint.factorId === TotpMultiFactorGenerator.FACTOR_ID) {
        multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(selectedHint.uid, otp);
      } else if (selectedHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        // Handle phone MFA (additional code needed for phone MFA)
      } else {
        throw new Error('Unsupported second factor');
      }

      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
      handleRedirect(userCredential.user);
    } catch (error) {
      setError('Invalid verification code. Please try again.');
      console.error(error);
    }
  };

  const handleRedirect = async (user) => {
    try {
      console.log('User UID:', user.uid); // Log the user's UID for debugging

      const idTokenResult = await user.getIdTokenResult();
      
      if (idTokenResult.claims.admin) {
        navigate('/admindashboard');
        return;
      }

      // Check if the user is associated with a bank
      const banksRef = collection(db, 'banks');
      const bankQuery = query(banksRef, where('userId', '==', user.uid));
      const bankSnapshot = await getDocs(bankQuery);

      if (!bankSnapshot.empty) {
        navigate('/bank-portal');
        return;
      }

      // Check if the user is a broker
      const brokersRef = collection(db, 'brokers');
      const brokerQuery = query(brokersRef, where('userId', '==', user.uid));
      const brokerSnapshot = await getDocs(brokerQuery);

      if (!brokerSnapshot.empty) {
        navigate('/broker-portal');
        return;
      }

      // Regular user - check for loan application
      const loanAppsRef = collection(db, 'loanApplications');
      const loanAppQuery = query(loanAppsRef, where('userId', '==', user.uid));
      const loanAppSnapshot = await getDocs(loanAppQuery);

      if (!loanAppSnapshot.empty) {
        navigate('/portal');
      } else {
        navigate('/entryform');
      }

    } catch (error) {
      console.error('Error during redirection:', error);
      console.error('Error details:', JSON.stringify(error, null, 2));
      setError('An error occurred during login. Please try again.');
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-blue-900 flex items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-sm space-y-10 bg-white p-8 rounded-lg shadow-lg">
        <div>
          <img className="mx-auto h-12 w-auto" src={logo} alt="Your Company" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
        {!mfaRequired ? (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            {showSignUpNotification && (
              <div className="text-sm text-center text-red-600">
                This email is not registered. Please sign up for an account.
                <div className="mt-2">
                  <button
                    type="button"
                    className="text-blue-600 hover:text-blue-500"
                    onClick={() => navigate('/signup')} // Adjust this path as needed
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            )}

            {error && <div className="text-sm text-red-600">{error}</div>}

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Sign in
              </button>
            </div>

            <div className="text-center">
              <button
                type="button"
                className="text-sm text-blue-600 hover:text-blue-500"
                onClick={handleForgotPassword}
              >
                Forgot password?
              </button>
            </div>
          </form>
        ) : (
          <form className="space-y-6" onSubmit={handleMfaSubmit}>
            <div>
              <label htmlFor="verification-code" className="sr-only">
                Verification Code
              </label>
              <input
                id="verification-code"
                name="verification-code"
                type="text"
                autoComplete="one-time-code"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Enter verification code"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>

            {error && <div className="text-sm text-red-600">{error}</div>}

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Verify and Sign in
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
